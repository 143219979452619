import ElsaJean from "../../assets/combatants/pornstar-b/elsa-jean.jpg";
import ElsaJeanIcon from "../../assets/combatants/pornstar-b/elsa-jean-icon.jpg";
import ElsaJeanWin from "../../assets/combatants/pornstar-b/elsa-jean-win.jpg";
import ElsaJeanLose from "../../assets/combatants/pornstar-b/elsa-jean-lose.jpg";
import ElsaJeanLes from "../../assets/combatants/pornstar-b/elsa-jean-lesb.jpg";

import EmilyAddison from "../../assets/combatants/pornstar-b/emily-addison.jpg";
import EmilyAddisonIcon from "../../assets/combatants/pornstar-b/emily-addison-icon.jpg";
import EmilyAddisonWin from "../../assets/combatants/pornstar-b/emily-addison-win.jpg";
import EmilyAddisonLose from "../../assets/combatants/pornstar-b/emily-addison-lose.jpg";
import EmilyAddisonLes from "../../assets/combatants/pornstar-b/emily-addison-lesb.jpg";

import HollyMichaels from "../../assets/combatants/pornstar-b/holly-michaels.jpg";
import HollyMichaelsIcon from "../../assets/combatants/pornstar-b/holly-michaels-icon.jpg";
import HollyMichaelsWin from "../../assets/combatants/pornstar-b/holly-michaels-win.jpg";
import HollyMichaelsLose from "../../assets/combatants/pornstar-b/holly-michaels-lose.jpg";
import HollyMichaelsLes from "../../assets/combatants/pornstar-b/holly-michaels-lesb.jpg";

import KaydenKross from "../../assets/combatants/pornstar-b/kayden-kross.jpg";
import KaydenKrossIcon from "../../assets/combatants/pornstar-b/kayden-kross-icon.jpg";
import KaydenKrossWin from "../../assets/combatants/pornstar-b/kayden-kross-win.jpg";
import KaydenKrossLose from "../../assets/combatants/pornstar-b/kayden-kross-lose.jpg";
import KaydenKrossLes from "../../assets/combatants/pornstar-b/kayden-kross-lesb.jpg";

import MiaKhalifa from "../../assets/combatants/pornstar-b/mia-khalifa.jpg";
import MiaKhalifaIcon from "../../assets/combatants/pornstar-b/mia-khalifa-icon.jpg";
import MiaKhalifaWin from "../../assets/combatants/pornstar-b/mia-khalifa-win.jpg";
import MiaKhalifaLose from "../../assets/combatants/pornstar-b/mia-khalifa-lose.jpg";
import MiaKhalifaLes from "../../assets/combatants/pornstar-b/mia-khalifa-lesb.jpg";

import MiaMalkova from "../../assets/combatants/pornstar-b/mia-malkova.jpg";
import MiaMalkovaIcon from "../../assets/combatants/pornstar-b/mia-malkova-icon.jpg";
import MiaMalkovaWin from "../../assets/combatants/pornstar-b/mia-malkova-win.jpg";
import MiaMalkovaLose from "../../assets/combatants/pornstar-b/mia-malkova-lose.jpg";
import MiaMalkovaLes from "../../assets/combatants/pornstar-b/mia-malkova-lesb.jpg";

import NaomiWoods from "../../assets/combatants/pornstar-b/naomi-woods.jpg";
import NaomiWoodsIcon from "../../assets/combatants/pornstar-b/naomi-woods-icon.jpg";
import NaomiWoodsWin from "../../assets/combatants/pornstar-b/naomi-woods-win.jpg";
import NaomiWoodsLose from "../../assets/combatants/pornstar-b/naomi-woods-lose.jpg";
import NaomiWoodsLes from "../../assets/combatants/pornstar-b/naomi-woods-lesb.jpg";

import RileeMarks from "../../assets/combatants/pornstar-b/rilee-marks.jpg";
import RileeMarksIcon from "../../assets/combatants/pornstar-b/rilee-marks-icon.jpg";
import RileeMarksWin from "../../assets/combatants/pornstar-b/rilee-marks-win.jpg";
import RileeMarksLose from "../../assets/combatants/pornstar-b/rilee-marks-lose.jpg";
import RileeMarksLes from "../../assets/combatants/pornstar-b/rilee-marks-lesb.jpg";

import SashaGrey from "../../assets/combatants/pornstar-b/sasha-grey.jpg";
import SashaGreyIcon from "../../assets/combatants/pornstar-b/sasha-grey-icon.jpg";
import SashaGreyWin from "../../assets/combatants/pornstar-b/sasha-grey-win.jpg";
import SashaGreyLose from "../../assets/combatants/pornstar-b/sasha-grey-lose.jpg";
import SashaGreyLes from "../../assets/combatants/pornstar-b/sasha-grey-lesb.jpg";

export const seriesId = "Pornstars - B";

export default {
  elsaJean: {
    seriesId,
    id: "elsaJean",
    name: "Elsa Jean",
    description: "Adventurous pornstar who debuted in 2015",
    img: ElsaJean,
    icon: ElsaJeanIcon,
    opWinImg: ElsaJeanWin,
    opLoseImg: ElsaJeanLose,
    opLoseLesImg: ElsaJeanLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "breasts",
    uniqueAttackText:
      "Elsa gently suckles xxxx's breasts, with seductive sweetness.",
    roughPlayLvl: -5,
    seductionResistance: 28,
    grapplingProwess: 22,
    tongueProwess: 32,
    touchProwess: 25,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 15,
    touchResistance: 30,
    breastResistance: 25,
    mouthResistance: 32,
    cockResistance: 35,
    vaginaResistance: 15,
    anusResistance: 5,
    orgasmLimit: 3,
  },
  emilyAddison: {
    seriesId,
    id: "emilyAddison",
    name: "Emily Addison",
    description: "Glamour model & one of the most popular centrefolds in the adult industry.",
    img: EmilyAddison,
    icon: EmilyAddisonIcon,
    opWinImg: EmilyAddisonWin,
    opLoseImg: EmilyAddisonLose,
    opLoseLesImg: EmilyAddisonLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "blue",
    hairColor: "brunette",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText:
      "Emily eagerly tugs on xxxx's shaft, pumping it with erotic energy.",
    roughPlayLvl: 10,
    seductionResistance: 35,
    grapplingProwess: 35,
    tongueProwess: 15,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 15,
    anusProwess: 10,
    touchResistance: 15,
    breastResistance: 25,
    mouthResistance: 25,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 10,
    orgasmLimit: 3,
  },
  hollyMichaels: {
    seriesId,
    id: "hollyMichaels",
    name: "Holly Michaels",
    description: "Began in MFC (MyFreeCams) website model (2009), before going hardcore.",
    img: HollyMichaels,
    icon: HollyMichaelsIcon,
    opWinImg: HollyMichaelsWin,
    opLoseImg: HollyMichaelsLose,
    opLoseLesImg: HollyMichaelsLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "brown",
    hairColor: "brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "vagina",
    prefTarget: "mouth",
    uniqueAttackText:
      "Holly rubs her soft pussy over xxxx's mouth, dripping her juices between them.",
    roughPlayLvl: -10,
    seductionResistance: 25,
    grapplingProwess: 35,
    tongueProwess: 25,
    touchProwess: 30,
    cockProwess: 15,
    vaginaProwess: 45,
    anusProwess: 15,
    touchResistance: 20,
    breastResistance: 25,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  kaydenKross: {
    seriesId,
    id: "kaydenKross",
    name: "Kayden Kross",
    description: "Critically acclaimed performer, producer and director who co-founded TRENCHCOATx",
    img: KaydenKross,
    icon: KaydenKrossIcon,
    opWinImg: KaydenKrossWin,
    opLoseImg: KaydenKrossLose,
    opLoseLesImg: KaydenKrossLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "mouth",
    uniqueAttackText:
      "Kayden's long tongue pushes it's way into xxxx's mouth, exploring hungrily.",
    roughPlayLvl: -10,
    seductionResistance: 40,
    grapplingProwess: 15,
    tongueProwess: 35,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 15,
    cockResistance: 15,
    vaginaResistance: 5,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  miaKhalifa: {
    seriesId,
    id: "miaKhalifa",
    name: "Mia Khalifa",
    description: "Pornhub's number 1 actress beforee leaving the industry.",
    img: MiaKhalifa,
    icon: MiaKhalifaIcon,
    opWinImg: MiaKhalifaWin,
    opLoseImg: MiaKhalifaLose,
    opLoseLesImg: MiaKhalifaLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "black",
    hairColor: "brown",
    skinColor: "tanned",
    height: 1,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "anus",
    uniqueAttackText:
      "Mia runs her tongue between xxxx's warm arsecheeks, pleasuring their back door.",
    roughPlayLvl: 25,
    seductionResistance: 45,
    grapplingProwess: 35,
    tongueProwess: 45,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 25,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 30,
    cockResistance: 45,
    vaginaResistance: 15,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  miaMalkova: {
    seriesId,
    id: "miaMalkova",
    name: "Mia Malkova",
    description: "Popular porn star and glamour model who made her porn debut in 2012.",
    img: MiaMalkova,
    icon: MiaMalkovaIcon,
    opWinImg: MiaMalkovaWin,
    opLoseImg: MiaMalkovaLose,
    opLoseLesImg: MiaMalkovaLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "hourglass",
    eyeColor: "Hazel",
    hairColor: "blonde",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "anus",
    uniqueAttackText:
      "Mia uses her tongue expertly to dig deep into xxxx's back passage.",
    roughPlayLvl: 15,
    seductionResistance: 25,
    grapplingProwess: 30,
    tongueProwess: 30,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 25,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 10,
    cockResistance: 15,
    vaginaResistance: 15,
    anusResistance: 55,
    orgasmLimit: 3,
  },
  naomiWoods: {
    seriesId,
    id: "naomiWoods",
    name: "Naomi Woods",
    description: "Pornstar, glamour model & Penthouse magazine’s “Pet of the Month” for January 2017",
    img: NaomiWoods,
    icon: NaomiWoodsIcon,
    opWinImg: NaomiWoodsWin,
    opLoseImg: NaomiWoodsLose,
    opLoseLesImg: NaomiWoodsLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "green",
    hairColor: "blonde",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText:
      "Naomi's cute little tongue, sweety slathers itself over xxxx's hard cock.",
    roughPlayLvl: -10,
    seductionResistance: 35,
    grapplingProwess: 10,
    tongueProwess: 30,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 10,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 15,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  rileeMarks: {
    seriesId,
    id: "rileeMarks",
    name: "Rilee Marks",
    description: "Started in 2011 at 19, now retired.",
    img: RileeMarks,
    icon: RileeMarksIcon,
    opWinImg: RileeMarksWin,
    opLoseImg: RileeMarksLose,
    opLoseLesImg: RileeMarksLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "brown",
    hairColor: "brown",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Rilee rubs her supple tits eagerly against xxxx's cock, smothering it with her soft tit meat.",
    roughPlayLvl: 5,
    seductionResistance: 20,
    grapplingProwess: 25,
    tongueProwess: 25,
    touchProwess: 30,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 15,
    touchResistance: 15,
    breastResistance: 25,
    mouthResistance: 10,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  sashaGrey: {
    seriesId,
    id: "sashaGrey",
    name: "Sasha Grey",
    description: "A highly accomplished actress, director, producer, writer, DJ and musician who has been taking the industry by storm since 2006.",
    img: SashaGrey,
    icon: SashaGreyIcon,
    opWinImg: SashaGreyWin,
    opLoseImg: SashaGreyLose,
    opLoseLesImg: SashaGreyLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "brown",
    hairColor: "brunette",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "mouth",
    prefTarget: "cock",
    uniqueAttackText:
      "Sasha spears her throat onto xxxx's cock, gagging loudly.",
    roughPlayLvl: 10,
    seductionResistance: 25,
    grapplingProwess: 25,
    tongueProwess: 15,
    touchProwess: 25,
    cockProwess: 15,
    vaginaProwess: 15,
    anusProwess: 10,
    touchResistance: 15,
    breastResistance: 25,
    mouthResistance: 20,
    cockResistance: 5,
    vaginaResistance: 5,
    anusResistance: 10,
    orgasmLimit: 3,
  },
};
