import male from "../../assets/gender/male.jpg";
import female from "../../assets/gender/female.jpg";
import futanari from "../../assets/gender/futa.jpg";
//bodyshape
import buxom from "../../assets/body-type/buxom.jpg";
import chubby from "../../assets/body-type/chubby.jpg";
import medium from "../../assets/body-type/medium.jpg";
import muscular from "../../assets/body-type/muscular.jpg";
import petite from "../../assets/body-type/petite.jpg";
import small from "../../assets/body-type/small.jpg";

export default {
  gender: {
    male,
    female,
    futanari,
  },
  bodyShape: {
    buxom,
    chubby,
    medium,
    muscular,
    petite,
    small,
  },
};
