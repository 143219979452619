import Aika from "../../assets/combatants/jav-a/aika.jpg";
import AikaIcon from "../../assets/combatants/jav-a/aika-icon.jpg";
import AikaWin from "../../assets/combatants/jav-a/aika-win.jpg";
import AikaLose from "../../assets/combatants/jav-a/aika-lose.jpg";
import AikaLes from "../../assets/combatants/jav-a/aika-lesb.jpg";

import AsahiMizuno from "../../assets/combatants/jav-a/asahi-mizuno.jpg";
import AsahiMizunoIcon from "../../assets/combatants/jav-a/asahi-mizuno-icon.jpg";
import AsahiMizunoWin from "../../assets/combatants/jav-a/asahi-mizuno-win.jpg";
import AsahiMizunoLose from "../../assets/combatants/jav-a/asahi-mizuno-lose.jpg";
import AsahiMizunoLes from "../../assets/combatants/jav-a/asahi-mizuno-lesb.jpg";

import EimiFukada from "../../assets/combatants/jav-a/eimi-fukada.jpg";
import EimiFukadaIcon from "../../assets/combatants/jav-a/eimi-fukada-icon.jpg";
import EimiFukadaWin from "../../assets/combatants/jav-a/eimi-fukada-win.jpg";
import EimiFukadaLose from "../../assets/combatants/jav-a/eimi-fukada-lose.jpg";
import EimiFukadaLes from "../../assets/combatants/jav-a/eimi-fukada-lesb.jpg";

import HibikiOtsuki from "../../assets/combatants/jav-a/hibiki-otsuki.jpg";
import HibikiOtsukiIcon from "../../assets/combatants/jav-a/hibiki-otsuki-icon.jpg";
import HibikiOtsukiWin from "../../assets/combatants/jav-a/hibiki-otsuki-win.jpg";
import HibikiOtsukiLose from "../../assets/combatants/jav-a/hibiki-otsuki-lose.jpg";
import HibikiOtsukiLes from "../../assets/combatants/jav-a/hibiki-otsuki-lesb.jpg";

import Julia from "../../assets/combatants/jav-a/julia.jpg";
import JuliaIcon from "../../assets/combatants/jav-a/julia-icon.jpg";
import JuliaWin from "../../assets/combatants/jav-a/julia-win.jpg";
import JuliaLose from "../../assets/combatants/jav-a/julia-lose.jpg";

import NozomiArimura from "../../assets/combatants/jav-a/nozomi-arimura.jpg";
import NozomiArimuraIcon from "../../assets/combatants/jav-a/nozomi-arimura-icon.jpg";
import NozomiArimuraWin from "../../assets/combatants/jav-a/nozomi-arimura-win.jpg";
import NozomiArimuraLose from "../../assets/combatants/jav-a/nozomi-arimura-lose.jpg";
import NozomiArimuraLes from "../../assets/combatants/jav-a/nozomi-arimura-lesb.jpg";

import ShuriAtomi from "../../assets/combatants/jav-a/shuri-atomi.jpg";
import ShuriAtomiIcon from "../../assets/combatants/jav-a/shuri-atomi-icon.jpg";
import ShuriAtomiWin from "../../assets/combatants/jav-a/shuri-atomi-win.jpg";
import ShuriAtomiLose from "../../assets/combatants/jav-a/shuri-atomi-lose.jpg";
import ShuriAtomiLes from "../../assets/combatants/jav-a/shuri-atomi-lesb.jpg";

import TsukasaAoi from "../../assets/combatants/jav-a/tsukasa-aoi.jpg";
import TsukasaAoiIcon from "../../assets/combatants/jav-a/tsukasa-aoi-icon.jpg";
import TsukasaAoiWin from "../../assets/combatants/jav-a/tsukasa-aoi-win.jpg";
import TsukasaAoiLose from "../../assets/combatants/jav-a/tsukasa-aoi-lose.jpg";

import YuiHatano from "../../assets/combatants/jav-a/yui-hatano.jpg";
import YuiHatanoIcon from "../../assets/combatants/jav-a/yui-hatano-icon.jpg";
import YuiHatanoWin from "../../assets/combatants/jav-a/yui-hatano-win.jpg";
import YuiHatanoLose from "../../assets/combatants/jav-a/yui-hatano-lose.jpg";
import YuiHatanoLes from "../../assets/combatants/jav-a/yui-hatano.jpg";

export const seriesId = "JAV - A";

export default {
  Aika: {
    seriesId,
    id: "Aika",
    name: "Aika",
    description: "JAV pornstar gal",
    img: Aika,
    icon: AikaIcon,
    opWinImg: AikaWin,
    opLoseImg: AikaLose,
    opLoseLesImg: AikaLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "soft",
    eyeColor: "blue",
    hairColor: "dirty blonde",
    skinColor: "tanned",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText:
      "Aika's tongue dives inbetween xxxx's lower lips and sensually explores her inner walls, also teasing her clit.",
    roughPlayLvl: 5,
    seductionResistance: 25,
    grapplingProwess: 15,
    tongueProwess: 25,
    touchProwess: 15,
    cockProwess: 10,
    vaginaProwess: 10,
    anusProwess: 5,
    touchResistance: 15,
    breastResistance: 10,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 5,
    anusResistance: 5,
    orgasmLimit: 3,
  },
  AsahiMizuno: {
    seriesId,
    id: "AsahiMizuno",
    name: "Asahi Mizuno",
    description: "Lovable JAV star.",
    img: AsahiMizuno,
    icon: AsahiMizunoIcon,
    opWinImg: AsahiMizunoWin,
    opLoseImg: AsahiMizunoLose,
    opLoseLesImg: AsahiMizunoLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "black",
    hairColor: "black",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText:
      "Asahi's roughly pump xxxx's shaft, deftly kneading it in a capable attempt as extracting any cum available.",
    roughPlayLvl: -15,
    seductionResistance: 10,
    grapplingProwess: 25,
    tongueProwess: 5,
    touchProwess: 20,
    cockProwess: 10,
    vaginaProwess: 10,
    anusProwess: 15,
    touchResistance: 5,
    breastResistance: 15,
    mouthResistance: 20,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  EimiFukada: {
    seriesId,
    id: "EimiFukada",
    name: "Eimi Fukada",
    description: "A renowned pornstar with beautiful eyes.",
    img: EimiFukada,
    icon: EimiFukadaIcon,
    opWinImg: EimiFukadaWin,
    opLoseImg: EimiFukadaLose,
    opLoseLesImg: EimiFukadaLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "brown",
    hairColor: "brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "anus",
    uniqueAttackText:
      "Eimi secretly pulls out a large dildo and uses it on xxxx's arse, digging deep and roughly.",
    roughPlayLvl: 10,
    seductionResistance: 20,
    grapplingProwess: 25,
    tongueProwess: 15,
    touchProwess: 35,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 15,
    touchResistance: 15,
    breastResistance: 5,
    mouthResistance: 15,
    cockResistance: 15,
    vaginaResistance: 20,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  HibikiOtsuki: {
    seriesId,
    id: "HibikiOtsuki",
    name: "Hibiki Otsuki",
    description: "Enthusiatic and sensuous pornstar",
    img: HibikiOtsuki,
    icon: HibikiOtsukiIcon,
    opWinImg: HibikiOtsukiWin,
    opLoseImg: HibikiOtsukiLose,
    opLoseLesImg: HibikiOtsukiLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "black",
    hairColor: "black",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "mouth",
    prefTarget: "breasts",
    uniqueAttackText:
      "Hibiki lovingly licks xxxx's tits, before suddenly biting her nipples animalistically.",
    roughPlayLvl: 25,
    seductionResistance: 20,
    grapplingProwess: 25,
    tongueProwess: 25,
    touchProwess: 15,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 25,
    touchResistance: 15,
    breastResistance: 25,
    mouthResistance: 5,
    cockResistance: 15,
    vaginaResistance: 20,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  Julia: {
    seriesId,
    id: "Julia",
    name: "Julia",
    description: "The busty seductress.",
    img: Julia,
    icon: JuliaIcon,
    opWinImg: JuliaWin,
    opLoseImg: JuliaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "brown",
    hairColor: "aubrun",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 5,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText:
      "Julia's incredibly long tongue explores the depths of xxxx's pussy, teasing and tasting areas never before teased.",
    roughPlayLvl: -10,
    seductionResistance: 20,
    grapplingProwess: 25,
    tongueProwess: 45,
    touchProwess: 20,
    cockProwess: 25,
    vaginaProwess: 25,
    anusProwess: 25,
    touchResistance: 35,
    breastResistance: 25,
    mouthResistance: 50,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  NozomiArimura: {
    seriesId,
    id: "NozomiArimura",
    name: "Nozomi Arimura",
    description: "A slender soft skinned JAV star",
    img: NozomiArimura,
    icon: NozomiArimuraIcon,
    opWinImg: NozomiArimuraWin,
    opLoseImg: NozomiArimuraLose,
    opLoseLesImg: NozomiArimuraLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "black",
    hairColor: "brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "mouth",
    uniqueAttackText:
      "Nozomi pushes her slight bust into xxxx's mouth, forcing her to taste her delicious breasts.",
    roughPlayLvl: -2,
    seductionResistance: 25,
    grapplingProwess: 15,
    tongueProwess: 10,
    touchProwess: 25,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 15,
    touchResistance: 15,
    breastResistance: 10,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 10,
    orgasmLimit: 3,
  },
  ShuriAtomi: {
    seriesId,
    id: "ShuriAtomi",
    name: "Shuri Atomi",
    description: "Tiny JAV star with high energy",
    img: ShuriAtomi,
    icon: ShuriAtomiIcon,
    opWinImg: ShuriAtomiWin,
    opLoseImg: ShuriAtomiLose,
    opLoseLesImg: ShuriAtomiLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "black",
    hairColor: "black",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "touch",
    uniqueAttackText:
      "Shiro hungry licks xxxx's skin, covering them in saliva, and pleasuring their body.",
    roughPlayLvl: 15,
    seductionResistance: 25,
    grapplingProwess: 25,
    tongueProwess: 25,
    touchProwess: 5,
    cockProwess: 10,
    vaginaProwess: 20,
    anusProwess: 5,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 20,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 5,
    orgasmLimit: 3,
  },
  TsukasaAoi: {
    seriesId,
    id: "TsukasaAoi",
    name: "Tsukasa Aoi",
    description: "A beautiful and gentle JAV star",
    img: TsukasaAoi,
    icon: TsukasaAoiIcon,
    opWinImg: TsukasaAoiWin,
    opLoseImg: TsukasaAoiLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "light brown",
    hairColor: "brown",
    skinColor: "pale",
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "touch",
    uniqueAttackText:
      "Tsukasa smothers her opponent with her shapely and tender breasts, tickled xxxx with her hard nipples.",
    roughPlayLvl: -15,
    seductionResistance: 25,
    grapplingProwess: 30,
    tongueProwess: 25,
    touchProwess: 30,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 25,
    touchResistance: 15,
    breastResistance: 25,
    mouthResistance: 5,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  YuiHatano: {
    seriesId,
    id: "YuiHatano",
    name: "Yui Hatano",
    description: "MILF JAV star",
    img: YuiHatano,
    icon: YuiHatanoIcon,
    opWinImg: YuiHatanoWin,
    opLoseImg: YuiHatanoLose,
    opLoseLesImg: YuiHatanoLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "brown",
    hairColor: "auburn",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText:
      "Yui impales herself on xxxx's cock, pushing it roughly into her throat, ignoring her gag reflex she deepthroats like a professional.",
    roughPlayLvl: 12,
    seductionResistance: 25,
    grapplingProwess: 30,
    tongueProwess: 40,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 15,
    anusProwess: 25,
    touchResistance: 35,
    breastResistance: 25,
    mouthResistance: 35,
    cockResistance: 15,
    vaginaResistance: 10,
    anusResistance: 25,
    orgasmLimit: 3,
  },
};
