import abby from "../../assets/combatants/underground/mid/abby.jpg";
import aisha from "../../assets/combatants/underground/mid/aisha.jpg";
import alanna from "../../assets/combatants/underground/mid/alanna.jpg";
import april from "../../assets/combatants/underground/mid/april.jpg";
import carter from "../../assets/combatants/underground/mid/carter.jpg";
import charli from "../../assets/combatants/underground/mid/charli.jpg";
import danica from "../../assets/combatants/underground/mid/danica.jpg";
import danna from "../../assets/combatants/underground/mid/danna.jpg";
import kylee from "../../assets/combatants/underground/mid/kylee.jpg";
import leona from "../../assets/combatants/underground/mid/leona.jpg";
import leslie from "../../assets/combatants/underground/mid/leslie.jpg";
import maia from "../../assets/combatants/underground/mid/maia.jpg";
import remy from "../../assets/combatants/underground/mid/remy.jpg";
import sully from "../../assets/combatants/underground/mid/sully.jpg";
import sylvia from "../../assets/combatants/underground/mid/sylvia.jpg";

export const OtherMid = {
  abby: {
    name: "Abby",
    description: "A seductive athlete, with a love of licking pussy",
    img: abby,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText: "Abby's long tongue deftly pleasures xxxx's vagina",
    seductionResistance: 25,
    grapplingProwess: 30,
    tongueProwess: 45,
    touchProwess: 25,
    cockProwess: 25,
    vaginaProwess: 38,
    anusProwess: 25,
    touchResistance: 45,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 10,
    vaginaResistance: 35,
    anusResistance: 10,
    orgasmLimit: 3,
  },
  aisha: {
    name: "Aisha",
    description: "A rich young noble girl fallen on hard times.",
    img: aisha,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText:
      "Aisha's tender looks aid in her otherwise unspectacular handjob technique",
    roughPlayLvl: -5,
    seductionResistance: 35,
    grapplingProwess: 20,
    tongueProwess: 35,
    touchProwess: 45,
    cockProwess: 25,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 15,
    anusResistance: 30,
    orgasmLimit: 3,
  },
  alanna: {
    name: "Alanna",
    description:
      "A sexy fashionista with rep for opening her legs for a model contract",
    img: alanna,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 4,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "vagina",
    prefTarget: "cock",
    uniqueAttackText:
      "alanna's long legs grab hold and push xxxx's hard cock deep in her wet pussy.",
    roughPlayLvl: 20,
    seductionResistance: 45,
    grapplingProwess: 20,
    tongueProwess: 35,
    touchProwess: 25,
    cockProwess: 20,
    vaginaProwess: 45,
    anusProwess: 20,
    // -- resistance is ability to endure pleasure, 1 - 100
    touchResistance: 25,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 10,
    vaginaResistance: 35,
    anusResistance: 40,
    orgasmLimit: 3,
  },
  april: {
    name: "April",
    description:
      "A voluptuous nurse in training, has a good record for helping erectile issues.",
    img: april,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText:
      "April uses her soft breasts to smother xxxx's cock with tit meat.",
    roughPlayLvl: -5,
    seductionResistance: 25,
    grapplingProwess: 35,
    tongueProwess: 35,
    touchProwess: 45,
    cockProwess: 25,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 20,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 30,
    orgasmLimit: 3,
  },
  carter: {
    name: "Carter",
    description: "A soft spoken librarian with a kinky side",
    img: carter,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "vagina",
    prefTarget: "vagina",
    uniqueAttackText: "Carter passionatly scissors herself against xxxx's pussy.",
    roughPlayLvl: 12,
    seductionResistance: 20,
    grapplingProwess: 20,
    tongueProwess: 15,
    touchProwess: 25,
    cockProwess: 20,
    vaginaProwess: 38,
    anusProwess: 25,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 10,
    cockResistance: 10,
    vaginaResistance: 35,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  charli: {
    name: "Charli",
    description: "An alluring indie girl addicted to semen.",
    img: charli,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText:
      "Charli buries xxxx's cock deep into her back passage moaning erotically",
    roughPlayLvl: 15,
    seductionResistance: 42,
    grapplingProwess: 20,
    tongueProwess: 35,
    touchProwess: 15,
    cockProwess: 25,
    vaginaProwess: 30,
    anusProwess: 35,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 25,
    vaginaResistance: 15,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  danica: {
    name: "Danica",
    description: "A successul glamour model.",
    img: danica,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "pussy",
    uniqueAttackText:
      "Danica buries her face between xxxx's pussy lips and softly licks with her wet tongue.",
    roughPlayLvl: -20,
    seductionResistance: 15,
    grapplingProwess: 42,
    tongueProwess: 35,
    touchProwess: 25,
    cockProwess: 10,
    vaginaProwess: 15,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 20,
    mouthResistance: 30,
    cockResistance: 10,
    vaginaResistance: 35,
    anusResistance: 40,
    orgasmLimit: 3,
  },
  danna: {
    name: "Danna",
    description: "A cheeky gym bunny with an alluring gaze.",
    img: danna,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 4,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Danna gropes xxxx's rack expertly, and switches between tweeking her nipples roughly.",
    roughPlayLvl: 20,
    seductionResistance: 25,
    grapplingProwess: 15,
    tongueProwess: 35,
    touchProwess: 35,
    cockProwess: 25,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 30,
    orgasmLimit: 3,
  },
  kylee: {
    name: "Kylee",
    description: "A cute drummer with a hidden feisty side.",
    img: kylee,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 5,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "touch",
    uniqueAttackText:
      "Kylee slides her soft pussy over xxxx's thigh, coating it with her fluids.",
    roughPlayLvl: 12,
    seductionResistance: 25,
    grapplingProwess: 25,
    tongueProwess: 15,
    touchProwess: 25,
    cockProwess: 20,
    vaginaProwess: 38,
    anusProwess: 25,
    touchResistance: 35,
    breastResistance: 25,
    mouthResistance: 10,
    cockResistance: 10,
    vaginaResistance: 35,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  leona: {
    name: "Leona",
    description: "A schoolgirl with a bad reputation for good blowjobs.",
    img: leona,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 5,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText:
      "Leona unhesitatingly buries xxxx's cock into her throat, gurgling and choking loudly as she deepthroats.",
    roughPlayLvl: 25,
    seductionResistance: 42,
    grapplingProwess: 25,
    tongueProwess: 45,
    touchProwess: 15,
    cockProwess: 25,
    vaginaProwess: 30,
    anusProwess: 35,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 25,
    vaginaResistance: 15,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  leslie: {
    name: "Leslie",
    description: "Milf and virginity stealer.",
    img: leslie,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "anus",
    prefTarget: "mouth",
    uniqueAttackText: "Leslie rides xxxx's face with her plump behind.",
    roughPlayLvl: -5,
    seductionResistance: 24,
    grapplingProwess: 34,
    tongueProwess: 32,
    touchProwess: 25,
    cockProwess: 10,
    vaginaProwess: 15,
    anusProwess: 32,
    touchResistance: 22,
    breastResistance: 20,
    mouthResistance: 30,
    cockResistance: 15,
    vaginaResistance: 35,
    anusResistance: 24,
    orgasmLimit: 3,
  },
  maia: {
    name: "Maia",
    description: "A sleepy gaming addict, with a eroge addiction.",
    img: maia,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText:
      "Maia buries her face into xxxx's pussy, and softly teases her flaps.",
    roughPlayLvl: 2,
    seductionResistance: 25,
    grapplingProwess: 35,
    tongueProwess: 35,
    touchProwess: 15,
    cockProwess: 25,
    vaginaProwess: 15,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 20,
    mouthResistance: 10,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 22,
    orgasmLimit: 3,
  },
  remy: {
    name: "Remy",
    description: "A ex shygirl, now a wholehearted slut who loves having her chest bukkake'd.",
    img: remy,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText:
      "Remy eagerly trys to milk xxxx's cock for its fresh creamy spunk.",
    roughPlayLvl: 10,
    seductionResistance: 32,
    grapplingProwess: 25,
    tongueProwess: 25,
    touchProwess: 35,
    cockProwess: 25,
    vaginaProwess: 30,
    anusProwess: 35,
    touchResistance: 15,
    breastResistance: 25,
    mouthResistance: 20,
    cockResistance: 25,
    vaginaResistance: 35,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  sully: {
    name: "Sully",
    description: "A cute gamer girl with a love of breasts.",
    img: sully,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText: "Sully lubes her breasts with spit, then rubs her nipples and tits eagerly against xxxx's rack.",
    roughPlayLvl: -5,
    seductionResistance: 34,
    grapplingProwess: 36,
    tongueProwess: 32,
    touchProwess: 25,
    cockProwess: 10,
    vaginaProwess: 25,
    anusProwess: 32,
    touchResistance: 22,
    breastResistance: 25,
    mouthResistance: 30,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 24,
    orgasmLimit: 3,
  },
  sylvia: {
    name: "Sylvia",
    description: "The local school's resident slut.",
    img: sylvia,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "breasts",
    uniqueAttackText:
      "Sylvia slathers xxxx's tits with soft licks and kisses, sucking hungrily on her nipples.",
    roughPlayLvl: 20,
    seductionResistance: 45,
    grapplingProwess: 15,
    tongueProwess: 35,
    touchProwess: 15,
    cockProwess: 25,
    vaginaProwess: 25,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 10,
    cockResistance: 35,
    vaginaResistance: 25,
    anusResistance: 22,
    orgasmLimit: 3,
  },
};
