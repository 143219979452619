import grace from "../../assets/combatants/underground/basic/grace.jpg";
import aubrey from "../../assets/combatants/underground/basic/aubrey.jpg";
import chloe from "../../assets/combatants/underground/basic/chloe.jpg";
import brooke from "../../assets/combatants/underground/basic/brooke.jpg";
import eleanor from "../../assets/combatants/underground/basic/eleanor.jpg";
import ella from "../../assets/combatants/underground/basic/ella.jpg";
import ellie from "../../assets/combatants/underground/basic/ellie.jpg";
import emily from "../../assets/combatants/underground/basic/emily.jpg";
import everly from "../../assets/combatants/underground/basic/everly.jpg";
import hannah from "../../assets/combatants/underground/basic/hannah.jpg";
import hazel from "../../assets/combatants/underground/basic/hazel.jpg";
import jane from "../../assets/combatants/underground/basic/jane.jpg";
import joanna from "../../assets/combatants/underground/basic/joanna.jpg";
import layla from "../../assets/combatants/underground/basic/layla.jpg";
import lily from "../../assets/combatants/underground/basic/lily.jpg";
import melanie from "../../assets/combatants/underground/basic/melanie.jpg";
import melissa from "../../assets/combatants/underground/basic/melissa.jpg";
import tama from "../../assets/combatants/underground/basic/tama.jpg";
import mila from "../../assets/combatants/underground/basic/mila.jpg";
import penelope from "../../assets/combatants/underground/basic/penelope.jpg";
import sarah from "../../assets/combatants/underground/basic/sarah.jpg";
import scarlett from "../../assets/combatants/underground/basic/scarlett.jpg";
import steph from "../../assets/combatants/underground/basic/steph.jpg";
import zuima from "../../assets/combatants/underground/basic/zuima.jpg";

export default {
  grace: {
    name: "Grace",
    description: "A cute swimsuit model.",
    img: grace,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "touch",
    uniqueAttackText: "Grace licks xxxx's body with a slobbering wet tongue",
    roughPlayLvl: 4,
    seductionResistance: 15,
    grapplingProwess: 10,
    tongueProwess: 20,
    touchProwess: 15,
    cockProwess: 15,
    vaginaProwess: 10,
    anusProwess: 5,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 10,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 10,
    orgasmLimit: 2,
  },
  aubrey: {
    name: "Aubrey",
    description: "A beautiful home designer",
    img: aubrey,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText: "Aubrey expertly fondle's xxxx's chest",
    roughPlayLvl: 14,
    seductionResistance: 15,
    grapplingProwess: 10,
    tongueProwess: 15,
    touchProwess: 20,
    cockProwess: 5,
    vaginaProwess: 10,
    anusProwess: 5,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 5,
    vaginaResistance: 5,
    anusResistance: 10,
    orgasmLimit: 2,
  },
  chloe: {
    name: "Chloe",
    description: "A sun loving, and lazy musician",
    img: chloe,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "vagina",
    prefTarget: "mouth",
    uniqueAttackText:
      "Chloe rides xxxx's face, soaking xxxx in her delicious pussy juices",
    roughPlayLvl: -10,
    seductionResistance: 5,
    grapplingProwess: 15,
    tongueProwess: 15,
    touchProwess: 20,
    cockProwess: 5,
    vaginaProwess: 20,
    anusProwess: 15,
    touchResistance: 5,
    breastResistance: 20,
    mouthResistance: 10,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 10,
    orgasmLimit: 2,
  },
  Brooke: {
    name: "Brooke",
    description: "A seductive little teen, who loves to run.",
    img: brooke,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "light blue",
    hairColor: "brunette",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText:
      "Brooke rides xxxx's cock with her tight arse, gobbling it deep into her back passage",
    roughPlayLvl: 14,
    seductionResistance: 15,
    grapplingProwess: 10,
    tongueProwess: 20,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 10,
    anusProwess: 20,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 10,
    orgasmLimit: 2,
  },
  eleanor: {
    name: "Eleanor",
    description: "A synchronized swimming prodigy.",
    img: eleanor,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText:
      "Eleanor nervously suckles xxxx's clit, turning red as she tastes her warm pussy juices",
    roughPlayLvl: -15,
    seductionResistance: 15,
    grapplingProwess: 15,
    tongueProwess: 20,
    touchProwess: 10,
    cockProwess: 5,
    vaginaProwess: 20,
    anusProwess: 5,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 10,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 10,
    orgasmLimit: 2,
  },
  ella: {
    name: "Ella",
    description: "A fashion obsessed socialite",
    img: ella,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "touch",
    prefTarget: "anus",
    uniqueAttackText:
      "Ella's gentle hand drives a wave of pleasure as she presses xxxx's anal erogenous zone",
    roughPlayLvl: 10,
    seductionResistance: 15,
    grapplingProwess: 5,
    tongueProwess: 5,
    touchProwess: 20,
    cockProwess: 5,
    vaginaProwess: 10,
    anusProwess: 15,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 10,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 20,
    orgasmLimit: 2,
  },
  ellie: {
    name: "Ellie",
    description:
      "Sexy teacher's assistant, with a bad repution for sleeping with young female students.",
    img: ellie,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "touch",
    uniqueAttackText:
      "Ellie slides her dripping pussy all over xxxx's soft skin",
    roughPlayLvl: 14,
    seductionResistance: 15,
    grapplingProwess: 10,
    tongueProwess: 20,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 10,
    anusProwess: 20,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 10,
    orgasmLimit: 2,
  },
  emily: {
    name: "Emily",
    description: "A powerful young water polo player.",
    img: emily,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "anus",
    prefTarget: "mouth",
    uniqueAttackText:
      "Emily grinds her powerfully tight buttcheeks over xxxx's face",
    roughPlayLvl: -15,
    seductionResistance: 15,
    grapplingProwess: 15,
    tongueProwess: 20,
    touchProwess: 10,
    cockProwess: 5,
    vaginaProwess: 20,
    anusProwess: 5,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 10,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 10,
    orgasmLimit: 2,
  },
  everly: {
    name: "Everly",
    description: "A seductive little goth girl",
    img: everly,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "breasts",
    uniqueAttackText:
      "Everly uses her expertly trained tongue to tease xxxx's breasts",
    roughPlayLvl: 25,
    seductionResistance: 15,
    grapplingProwess: 5,
    tongueProwess: 25,
    touchProwess: 10,
    cockProwess: 5,
    vaginaProwess: 10,
    anusProwess: 5,
    touchResistance: 15,
    breastResistance: 10,
    mouthResistance: 10,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 10,
    orgasmLimit: 2,
  },
  hannah: {
    name: "Hannah",
    description: "A shy young, dedicated housewife.",
    img: hannah,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText:
      "Hannah's nervous hands gently pump xxxx's cock, in a adorable and lovably shy way",
    roughPlayLvl: 14,
    seductionResistance: 15,
    grapplingProwess: 10,
    tongueProwess: 20,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 10,
    anusProwess: 20,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 10,
    orgasmLimit: 2,
  },
  hazel: {
    name: "Hazel",
    description: "A seductive young sexual tease.",
    img: hazel,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "vagina",
    prefTarget: "vagina",
    uniqueAttackText:
      "Hazel roughly scissors xxxx, expertly teasing her clit",
    roughPlayLvl: 15,
    seductionResistance: 5,
    grapplingProwess: 25,
    tongueProwess: 10,
    touchProwess: 10,
    cockProwess: 5,
    vaginaProwess: 20,
    anusProwess: 5,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 10,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 15,
    orgasmLimit: 2,
  },
  jane: {
    name: "Jane",
    description: "A bubbly young dance student",
    img: jane,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "anus",
    prefTarget: "touch",
    uniqueAttackText:
      "Jane rubs her deliciously soft arse over xxxx's body",
    roughPlayLvl: 5,
    seductionResistance: 10,
    grapplingProwess: 20,
    tongueProwess: 25,
    touchProwess: 10,
    cockProwess: 15,
    vaginaProwess: 10,
    anusProwess: 15,
    touchResistance: 15,
    breastResistance: 10,
    mouthResistance: 10,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 15,
    orgasmLimit: 2,
  },
  joanna: {
    name: "Joanna",
    description:
      "A busty teacher who lost her job by rewarding her class with a free for all bukkake.",
    img: joanna,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText:
      "Joanna pumps xxxx's cock, eagerly hoping to coat her mouth with fresh cum",
    roughPlayLvl: 20,
    seductionResistance: 15,
    grapplingProwess: 15,
    tongueProwess: 20,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 10,
    anusProwess: 20,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 20,
    cockResistance: 5,
    vaginaResistance: 5,
    anusResistance: 10,
    orgasmLimit: 2,
  },
  layla: {
    name: "Layla",
    description: "An affectionate young model.",
    img: layla,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "touch",
    uniqueAttackText: "Layla rubs her soft naked body against xxxx's purring adorably",
    roughPlayLvl: -5,
    seductionResistance: 5,
    grapplingProwess: 20,
    tongueProwess: 15,
    touchProwess: 15,
    cockProwess: 5,
    vaginaProwess: 20,
    anusProwess: 5,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 10,
    cockResistance: 5,
    vaginaResistance: 20,
    anusResistance: 15,
    orgasmLimit: 2,
  },
  lily: {
    name: "Lily",
    description: "A slender tease, who loves attention.",
    img: lily,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "breasts",
    uniqueAttackText: "Reta runs her slippery pussy lips over xxxx's breasts",
    roughPlayLvl: -10,
    seductionResistance: 20,
    grapplingProwess: 5,
    tongueProwess: 20,
    touchProwess: 10,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 5,
    touchResistance: 15,
    breastResistance: 10,
    mouthResistance: 20,
    cockResistance: 5,
    vaginaResistance: 10,
    anusResistance: 5,
    orgasmLimit: 2,
  },
  melanie: {
    name: "Melanie",
    description: "A supple and flirtatious aerobics enthusiast.",
    img: melanie,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "anus",
    prefTarget: "vagina",
    uniqueAttackText:
      "Melanie reveals a dildo she inserts into her arse and uses to fuck xxxx's pussy",
    roughPlayLvl: 15,
    seductionResistance: 15,
    grapplingProwess: 20,
    tongueProwess: 15,
    touchProwess: 15,
    cockProwess: 5,
    vaginaProwess: 20,
    anusProwess: 20,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 10,
    cockResistance: 5,
    vaginaResistance: 10,
    anusResistance: 25,
    orgasmLimit: 2,
  },
  melissa: {
    name: "Melissa",
    description: "A sultry young delinquent.",
    img: melissa,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "touch",
    uniqueAttackText:
      "Melissa slathers xxxx's body with wet kisses and soft licks",
    roughPlayLvl: 10,
    seductionResistance: 10,
    grapplingProwess: 15,
    tongueProwess: 20,
    touchProwess: 10,
    cockProwess: 15,
    vaginaProwess: 10,
    anusProwess: 5,
    touchResistance: 15,
    breastResistance: 10,
    mouthResistance: 10,
    cockResistance: 5,
    vaginaResistance: 10,
    anusResistance: 5,
    orgasmLimit: 2,
  },
  Tama: {
    name: "Tama",
    description: "A supple and flirtatious aerobics enthusiast.",
    img: tama,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "shapely",
    eyeColor: "gold",
    hairColor: "black",
    skinColor: "dark",
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "mouth",
    uniqueAttackText:
      "Tama's soft dark skinned hands pleasurably explore xxxx's wet mouth",
    roughPlayLvl: -5,
    seductionResistance: 20,
    grapplingProwess: 10,
    tongueProwess: 15,
    touchProwess: 20,
    cockProwess: 5,
    vaginaProwess: 20,
    anusProwess: 20,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 15,
    cockResistance: 5,
    vaginaResistance: 10,
    anusResistance: 20,
    orgasmLimit: 2,
  },
  mila: {
    name: "Mila",
    description: "A kind and loving young teacher.",
    img: mila,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "cock",
    uniqueAttackText:
      "Mila moans and bucks as she wriggles unconfidently on xxxx's hard cock",
    roughPlayLvl: 2,
    seductionResistance: 15,
    grapplingProwess: 10,
    tongueProwess: 15,
    touchProwess: 15,
    cockProwess: 5,
    vaginaProwess: 20,
    anusProwess: 10,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 10,
    cockResistance: 5,
    vaginaResistance: 10,
    anusResistance: 5,
    orgasmLimit: 2,
  },
  penelope: {
    name: "Penelope",
    description: "An emotional but intelligent law student.",
    img: penelope,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "anus",
    prefTarget: "touch",
    uniqueAttackText:
      "Penelope softly runs along xxxx's skin with her squishy bottom",
    roughPlayLvl: -15,
    seductionResistance: 12,
    grapplingProwess: 15,
    tongueProwess: 20,
    touchProwess: 10,
    cockProwess: 12,
    vaginaProwess: 12,
    anusProwess: 10,
    touchResistance: 11,
    breastResistance: 10,
    mouthResistance: 16,
    cockResistance: 5,
    vaginaResistance: 12,
    anusResistance: 15,
    orgasmLimit: 2,
  },
  sarah: {
    name: "Sarah",
    description: "An overconfident milf stripper.",
    img: sarah,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "anus",
    uniqueAttackText:
      "Sarah's long tongue enthusiastically explores xxxx's back passage",
    roughPlayLvl: -5,
    seductionResistance: 10,
    grapplingProwess: 10,
    tongueProwess: 20,
    touchProwess: 20,
    cockProwess: 5,
    vaginaProwess: 12,
    anusProwess: 16,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 5,
    cockResistance: 5,
    vaginaResistance: 10,
    anusResistance: 14,
    orgasmLimit: 2,
  },
  scarlett: {
    name: "Scarlett",
    description: "A shy young painter.",
    img: scarlett,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 0,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText:
      "Scarlett's delicate fingers, lovingly pleasure xxxx's dripping pussy",
    roughPlayLvl: -10,
    seductionResistance: 16,
    grapplingProwess: 14,
    tongueProwess: 15,
    touchProwess: 18,
    cockProwess: 8,
    vaginaProwess: 10,
    anusProwess: 12,
    touchResistance: 2,
    breastResistance: 12,
    mouthResistance: 18,
    cockResistance: 5,
    vaginaResistance: 14,
    anusResistance: 12,
    orgasmLimit: 2,
  },
  steph: {
    name: "Steph",
    description: "a tomboy swimmer and general troublemaker.",
    img: steph,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "mouth",
    uniqueAttackText: "Steph forces her sopping wet pussy onto xxxx's face",
    roughPlayLvl: 5,
    seductionResistance: 18,
    grapplingProwess: 15,
    tongueProwess: 10,
    touchProwess: 10,
    cockProwess: 12,
    vaginaProwess: 18,
    anusProwess: 12,
    touchResistance: 15,
    breastResistance: 8,
    mouthResistance: 16,
    cockResistance: 20,
    vaginaResistance: 12,
    anusResistance: 18,
    orgasmLimit: 2,
  },
  Zuima: {
    name: "Zuima",
    description: "An timid boulder climber and class rep.",
    img: zuima,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "purple",
    hairColor: "purple",
    skinColor: "light",
    height: 0,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 1,
    throatSize: 1,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText:
      "Zuima gently rubs xxxx's cock over her budding breasts, moaning as it runs over her nipples",
    roughPlayLvl: 0,
    seductionResistance: 10,
    grapplingProwess: 10,
    tongueProwess: 8,
    touchProwess: 10,
    cockProwess: 5,
    vaginaProwess: 12,
    anusProwess: 16,
    touchResistance: 5,
    breastResistance: 10,
    mouthResistance: 5,
    cockResistance: 5,
    vaginaResistance: 10,
    anusResistance: 8,
    orgasmLimit: 2,
  },
};
