import Avril from "../../assets/combatants/musician/avril-lavigne.jpg";
import AvrilIcon from "../../assets/combatants/musician/avril-lavigne-icon.jpg";
import AvrilWin from "../../assets/combatants/musician/avril-lavigne-win.jpg";
import AvrilLose from "../../assets/combatants/musician/avril-lavigne-lose.jpg";
import AvrilLes from "../../assets/combatants/musician/avril-lavigne-lesb.jpg";

import Cheryl from "../../assets/combatants/musician/cheryl-cole.jpg";
import CherylIcon from "../../assets/combatants/musician/cheryl-cole-icon.jpg";
import CherylWin from "../../assets/combatants/musician/cheryl-cole-win.jpg";
import CherylLose from "../../assets/combatants/musician/cheryl-cole-lose.jpg";
import CherylLes from "../../assets/combatants/musician/cheryl-cole-lesb.jpg";

import DuaLipa from "../../assets/combatants/musician/dua-lipa.jpg";
import DuaLipaIcon from "../../assets/combatants/musician/dua-lipa-icon.jpg";
import DuaLipaWin from "../../assets/combatants/musician/dua-lipa-win.jpg";
import DuaLipaLose from "../../assets/combatants/musician/dua-lipa-lose.jpg";

import HilaryDuff from "../../assets/combatants/musician/hilary-duff.jpg";
import HilaryDuffIcon from "../../assets/combatants/musician/hilary-duff-icon.jpg";
import HilaryDuffWin from "../../assets/combatants/musician/hilary-duff-win.jpg";
import HilaryDuffLose from "../../assets/combatants/musician/hilary-duff-lose.jpg";
import HilaryDuffLes from "../../assets/combatants/musician/hilary-duff-lesb.jpg";

import KatyPerry from "../../assets/combatants/musician/katy-perry.jpg";
import KatyPerryIcon from "../../assets/combatants/musician/katy-perry-icon.jpg";
import KatyPerryWin from "../../assets/combatants/musician/katy-perry-win.jpg";
import KatyPerryLose from "../../assets/combatants/musician/katy-perry-lose.jpg";

import Lana from "../../assets/combatants/musician/lana-del-rey.jpg";
import LanaIcon from "../../assets/combatants/musician/lana-del-rey-icon.jpg";
import LanaWin from "../../assets/combatants/musician/lana-del-rey-win.jpg";
import LanaLose from "../../assets/combatants/musician/lana-del-rey-lose.jpg";

import SelenaGomez from "../../assets/combatants/musician/selena-gomez.jpg";
import SelenaGomezIcon from "../../assets/combatants/musician/selena-gomez-icon.jpg";
import SelenaGomezWin from "../../assets/combatants/musician/selena-gomez-win.jpg";
import SelenaGomezLose from "../../assets/combatants/musician/selena-gomez-lose.jpg";
import SelenaGomezLes from "../../assets/combatants/musician/selena-gomez-lesb.jpg";

import TaylorMomsen from "../../assets/combatants/musician/taylor-momsen.jpg";
import TaylorMomsenIcon from "../../assets/combatants/musician/taylor-momsen-icon.jpg";
import TaylorMomsenWin from "../../assets/combatants/musician/taylor-momsen-win.jpg";
import TaylorMomsenLose from "../../assets/combatants/musician/taylor-momsen-lose.jpg";
import TaylorMomsenLes from "../../assets/combatants/musician/taylor-momsen-lesb.jpg";

import TaylorSwift from "../../assets/combatants/musician/taylor-swift.jpg";
import TaylorSwiftIcon from "../../assets/combatants/musician/taylor-swift-icon.jpg";
import TaylorSwiftWin from "../../assets/combatants/musician/taylor-swift-win.jpg";
import TaylorSwiftLose from "../../assets/combatants/musician/taylor-swift-lose.jpg";
import TaylorSwiftLes from "../../assets/combatants/musician/taylor-swift.jpg";

export const seriesId = "Musician - A";

export default {
  Avril: {
    seriesId,
    id: "Avril Lavigne",
    name: "Avril",
    description: "",
    img: Avril,
    icon: AvrilIcon,
    opWinImg: AvrilWin,
    opLoseImg: AvrilLose,
    opLoseLesImg: AvrilLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "youthful",
    eyeColor: "green",
    hairColor: "colored blonde",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "mouth",
    uniqueAttackText:
      "Avril pushes her heavy tongue into xxxx's mouth, tasting her delicious mouth.",
    roughPlayLvl: 10,
    seductionResistance: 25,
    grapplingProwess: 35,
    tongueProwess: 34,
    touchProwess: 28,
    cockProwess: 25,
    vaginaProwess: 20,
    anusProwess: 15,
    touchResistance: 25,
    breastResistance: 40,
    mouthResistance: 20,
    cockResistance: 25,
    vaginaResistance: 25,
    anusResistance: 18,
    orgasmLimit: 3,
  },
  Cheryl: {
    seriesId,
    id: "Cheryl",
    name: "Cheryl Cole",
    description: "",
    img: Cheryl,
    icon: CherylIcon,
    opWinImg: CherylWin,
    opLoseImg: CherylLose,
    opLoseLesImg: CherylLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "brown",
    hairColor: "black",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "touch",
    uniqueAttackText:
      "Cheryl hungry licks xxxx's skin, covering them in saliva, and savouring their taste.",
    roughPlayLvl: 5,
    seductionResistance: 25,
    grapplingProwess: 35,
    tongueProwess: 25,
    touchProwess: 35,
    cockProwess: 20,
    vaginaProwess: 30,
    anusProwess: 15,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 34,
    cockResistance: 30,
    vaginaResistance: 25,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  DuaLipa: {
    seriesId,
    id: "DuaLipa",
    name: "Dua Lipa",
    description: "",
    img: DuaLipa,
    icon: DuaLipaIcon,
    opWinImg: DuaLipaWin,
    opLoseImg: DuaLipaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "brown",
    hairColor: "brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "touch",
    uniqueAttackText:
      "Dua smothers her opponent with her soft body, tickling xxxx with her small, cute nipples.",
    roughPlayLvl: 5,
    seductionResistance: 40,
    grapplingProwess: 50,
    tongueProwess: 35,
    touchProwess: 35,
    cockProwess: 25,
    vaginaProwess: 45,
    anusProwess: 45,
    touchResistance: 45,
    breastResistance: 25,
    mouthResistance: 35,
    cockResistance: 25,
    vaginaResistance: 35,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  HilaryDuff: {
    seriesId,
    id: "HilaryDuff",
    name: "Hilary Duff",
    description: "",
    img: HilaryDuff,
    icon: HilaryDuffIcon,
    opWinImg: HilaryDuffWin,
    opLoseImg: HilaryDuffLose,
    opLoseLesImg: HilaryDuffLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "light brown",
    hairColor: "blonde",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText:
      "Hilary impales herself on xxxx's thick meat pole, gagging on it roughly.",
    roughPlayLvl: 12,
    seductionResistance: 35,
    grapplingProwess: 40,
    tongueProwess: 40,
    touchProwess: 20,
    cockProwess: 25,
    vaginaProwess: 15,
    anusProwess: 25,
    touchResistance: 35,
    breastResistance: 25,
    mouthResistance: 35,
    cockResistance: 25,
    vaginaResistance: 30,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  KatyPerry: {
    seriesId,
    id: "KatyPerry",
    name: "Katy Perry",
    description: "",
    img: KatyPerry,
    icon: KatyPerryIcon,
    opWinImg: KatyPerryWin,
    opLoseImg: KatyPerryLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "green",
    hairColor: "black",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Katy roughly gropes xxxx's breasts, kneading them lovingly.",
    roughPlayLvl: 15,
    seductionResistance: 32,
    grapplingProwess: 45,
    tongueProwess: 15,
    touchProwess: 45,
    cockProwess: 15,
    vaginaProwess: 35,
    anusProwess: 10,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 20,
    cockResistance: 35,
    vaginaResistance: 25,
    anusResistance: 30,
    orgasmLimit: 3,
  },
  Lana: {
    seriesId,
    id: "Lana",
    name: "Lana Del Rey",
    description: "",
    img: Lana,
    icon: LanaIcon,
    opWinImg: LanaWin,
    opLoseImg: LanaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "brown",
    hairColor: "brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText: "Lana rubs her soft fingers over xxxx's sopping cunt.",
    roughPlayLvl: 5,
    seductionResistance: 28,
    grapplingProwess: 42,
    tongueProwess: 25,
    touchProwess: 35,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 35,
    touchResistance: 35,
    breastResistance: 45,
    mouthResistance: 40,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  SelenaGomez: {
    seriesId,
    id: "SelenaGomez",
    name: "Selena Gomez",
    description: "",
    img: SelenaGomez,
    icon: SelenaGomezIcon,
    opWinImg: SelenaGomezWin,
    opLoseImg: SelenaGomezLose,
    opLoseLesImg: SelenaGomezLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "youthful",
    eyeColor: "brown",
    hairColor: "black",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText:
      "Selena's hungry tongue, runs itself over xxxx's throbbing cock.",
    roughPlayLvl: -10,
    seductionResistance: 35,
    grapplingProwess: 40,
    tongueProwess: 40,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 25,
    cockResistance: 15,
    vaginaResistance: 42,
    anusResistance: 38,
    orgasmLimit: 3,
  },
  TaylorMomsen: {
    seriesId,
    id: "TaylorMomsen",
    name: "Taylor Momsen",
    description: "",
    img: TaylorMomsen,
    icon: TaylorMomsenIcon,
    opWinImg: TaylorMomsenWin,
    opLoseImg: TaylorMomsenLose,
    opLoseLesImg: TaylorMomsenLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "blue",
    hairColor: "white",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "anus",
    uniqueAttackText:
      "taylor uses her large tongue to plunder deep into xxxx's arse.",
    roughPlayLvl: 15,
    seductionResistance: 52,
    grapplingProwess: 38,
    tongueProwess: 42,
    touchProwess: 20,
    cockProwess: 35,
    vaginaProwess: 25,
    anusProwess: 35,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 45,
    vaginaResistance: 25,
    anusResistance: 55,
    orgasmLimit: 3,
  },
  TaylorSwift: {
    seriesId,
    id: "TaylorSwift",
    name: "Taylor Swift",
    description: "",
    img: TaylorSwift,
    icon: TaylorSwiftIcon,
    opWinImg: TaylorSwiftWin,
    opLoseImg: TaylorSwiftLose,
    opLoseLesImg: TaylorSwiftLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "brown",
    hairColor: "auburn",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText:
      "Taylor force's xxxx's cock deep into the back of her throat, looking up sensually as she does.",
    roughPlayLvl: 25,
    seductionResistance: 35,
    grapplingProwess: 42,
    tongueProwess: 35,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 45,
    breastResistance: 25,
    mouthResistance: 35,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 20,
    orgasmLimit: 3,
  },
};
