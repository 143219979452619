import Angela from "../../assets/combatants/pornstar-a/angela.jpg";
import AngelaIcon from "../../assets/combatants/pornstar-a/angela-icon.jpg";
import AngelaWin from "../../assets/combatants/pornstar-a/angela-win.jpg";
import AngelaLose from "../../assets/combatants/pornstar-a/angela-lose.jpg";
import AngelaLes from "../../assets/combatants/pornstar-a/angela-lesb.jpg";

import Bree from "../../assets/combatants/pornstar-a/bree.jpg";
import BreeIcon from "../../assets/combatants/pornstar-a/bree-icon.jpg";
import BreeWin from "../../assets/combatants/pornstar-a/bree-win.jpg";
import BreeLose from "../../assets/combatants/pornstar-a/bree-lose.jpg";
import BreeLes from "../../assets/combatants/pornstar-a/bree-lesb.jpg";

import Bunny from "../../assets/combatants/pornstar-a/bunny.jpg";
import BunnyIcon from "../../assets/combatants/pornstar-a/bunny-icon.jpg";
import BunnyWin from "../../assets/combatants/pornstar-a/bunny-win.jpeg";
import BunnyLose from "../../assets/combatants/pornstar-a/bunny-lose.jpg";
import BunnyLes from "../../assets/combatants/pornstar-a/bunny-lesb.jpg";

import ElizaJane from "../../assets/combatants/pornstar-a/eliza-jane.jpg";
import ElizaJaneIcon from "../../assets/combatants/pornstar-a/eliza-jane-icon.jpg";
import ElizaJaneWin from "../../assets/combatants/pornstar-a/eliza-jane-win.jpg";
import ElizaJaneLose from "../../assets/combatants/pornstar-a/eliza-jane-lose.jpg";
import ElizaJaneLes from "../../assets/combatants/pornstar-a/eliza-jane-lesb.jpg";

import EmilyWillis from "../../assets/combatants/pornstar-a/emily-willis.jpg";
import EmilyWillisIcon from "../../assets/combatants/pornstar-a/emily-willis-icon.jpg";
import EmilyWillisWin from "../../assets/combatants/pornstar-a/emily-willis-win.jpg";
import EmilyWillisLose from "../../assets/combatants/pornstar-a/emily-willis-lose.jpg";
import EmilyWillisLes from "../../assets/combatants/pornstar-a/emily-willis-lesb.jpg";

import JennaIvory from "../../assets/combatants/pornstar-a/jenna-ivory.jpg";
import JennaIvoryIcon from "../../assets/combatants/pornstar-a/jenna-ivory-icon.jpg";
import JennaIvoryWin from "../../assets/combatants/pornstar-a/jenna-ivory-win.jpg";
import JennaIvoryLose from "../../assets/combatants/pornstar-a/jenna-ivory-lose.jpg";
import JennaIvoryLes from "../../assets/combatants/pornstar-a/jenna-ivory-lesb.jpg";

import JenniLee from "../../assets/combatants/pornstar-a/jenni-lee.jpg";
import JenniLeeIcon from "../../assets/combatants/pornstar-a/jenni-lee-icon.jpg";
import JenniLeeWin from "../../assets/combatants/pornstar-a/jenni-lee-win.jpg";
import JenniLeeLose from "../../assets/combatants/pornstar-a/jenni-lee-lose.jpg";
import JenniLeeLes from "../../assets/combatants/pornstar-a/jenni-lee-lesb.jpg";

import LilyCarter from "../../assets/combatants/pornstar-a/lily-carter.jpg";
import LilyCarterIcon from "../../assets/combatants/pornstar-a/lily-carter-icon.jpg";
import LilyCarterWin from "../../assets/combatants/pornstar-a/lily-carter-win.jpg";
import LilyCarterLose from "../../assets/combatants/pornstar-a/lily-carter-lose.jpg";
import LilyCarterLes from "../../assets/combatants/pornstar-a/lily-carter-lesb.jpg";

import Riley from "../../assets/combatants/pornstar-a/riley.jpg";
import RileyIcon from "../../assets/combatants/pornstar-a/riley-icon.jpg";
import RileyWin from "../../assets/combatants/pornstar-a/riley-win.jpg";
import RileyLose from "../../assets/combatants/pornstar-a/riley-lose.jpg";
import RileyLes from "../../assets/combatants/pornstar-a/riley-lesb.jpg";

export const seriesId = "Pornstars - A";

export default {
  angela: {
    seriesId,
    id: "angela",
    name: "Angela",
    description: "Sultry blonde pornstar, Penthouse Pet & Playboy CyberGirl",
    img: Angela,
    icon: AngelaIcon,
    opWinImg: AngelaWin,
    opLoseImg: AngelaLose,
    opLoseLesImg: AngelaLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "mouth",
    uniqueAttackText:
      "Angela's soft little tongue slips it's squishy way into xxxx's mouth, exploring ==== mouth passionately, and tasting all over ==== tongue.",
    roughPlayLvl: -12, 
    seductionResistance: 12,
    grapplingProwess: 5,
    tongueProwess: 25,
    touchProwess: 25,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 15,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 22,
    cockResistance: 5,
    vaginaResistance: 15,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  bree: {
    seriesId,
    id: "bree",
    name: "Bree",
    description:
      "Extremely cute, buxom, and curvaceous strawberry blonde from Montana.",
    img: Bree,
    icon: BreeIcon,
    opWinImg: BreeWin,
    opLoseImg: BreeLose,
    opLoseLesImg: BreeLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "green",
    hairColor: "blonde",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Bree rubs her supple tits eagerly against xxxx's, tickling her nipples against her opponent's.",
    roughPlayLvl: 15,
    seductionResistance: 10,
    grapplingProwess: 15,
    tongueProwess: 25,
    touchProwess: 23,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 15,
    touchResistance: 15,
    breastResistance: 25,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 22,
    orgasmLimit: 3,
  },
  bunny: {
    seriesId,
    id: "bunny",
    name: "Bunny",
    description: "Bunny Colby is a stunning adult film star, cam girl and glamour model from Texas",
    img: Bunny,
    icon: BunnyIcon,
    opWinImg: BunnyWin,
    opLoseImg: BunnyLose,
    opLoseLesImg: BunnyLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "hourglass",
    eyeColor: "green",
    hairColor: "blonde",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText:
      "Bunny mounts you like an animal, jamming your hard cock deep into her back passage, she screams as she forces you deep into her.",
    roughPlayLvl: 40,
    seductionResistance: 10,
    grapplingProwess: 15,
    tongueProwess: 20,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 25,
    touchResistance: 25,
    breastResistance: 45,
    mouthResistance: 10,
    cockResistance: 35,
    vaginaResistance: 25,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  elizaJane: {
    seriesId,
    id: "elizaJane",
    name: "Eliza Jane",
    description: "Eliza Jane, cam girl, model and adult film star.",
    img: ElizaJane,
    icon: ElizaJaneIcon,
    opWinImg: ElizaJaneWin,
    opLoseImg: ElizaJaneLose,
    opLoseLesImg: ElizaJaneLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "soft",
    eyeColor: "brown",
    hairColor: "blonde",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "breasts",
    uniqueAttackText:
      "Eliza hungril sucking xxxx's nipples and closign her eyes in pleasure.",
    roughPlayLvl: 20,
    seductionResistance: 10,
    grapplingProwess: 15,
    tongueProwess: 12,
    touchProwess: 2,
    cockProwess: 15,
    vaginaProwess: 22,
    anusProwess: 10,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 5,
    vaginaResistance: 21,
    anusResistance: 10,
    orgasmLimit: 3,
  },
  emilyWillis: {
    seriesId,
    id: "emilyWillis",
    name: "Emily Willis",
    description:
      "Emily Willis won “Best Anal Sex Scene” along with with Ramon Nomar.",
    img: EmilyWillis,
    icon: EmilyWillisIcon,
    opWinImg: EmilyWillisWin,
    opLoseImg: EmilyWillisLose,
    opLoseLesImg: EmilyWillisLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "brown",
    hairColor: "brunette",
    skinColor: "tanned",
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "breasts",
    uniqueAttackText:
      "Emily hungrily slathers xxxx's breasts with her tongue, eagerly sucking her nipples and savouring their sweet, sweaty taste.",
    roughPlayLvl: 20,
    seductionResistance: 11,
    grapplingProwess: 11,
    tongueProwess: 25,
    touchProwess: 2,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 10,
    touchResistance: 15,
    breastResistance: 20,
    mouthResistance: 20,
    cockResistance: 5,
    vaginaResistance: 20,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  jennaIvory: {
    seriesId,
    id: "jennaIvory",
    name: "Jenna Ivory",
    description: "Jenna is an adult film star, cover model & Penthouse’s “Pet of the Month” ,June 2015.",
    img: JennaIvory,
    icon: JennaIvoryIcon,
    opWinImg: JennaIvoryWin,
    opLoseImg: JennaIvoryLose,
    opLoseLesImg: JennaIvoryLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "hourglass",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText:
      "Jenna's delicate fingers explore xxxx's pussy, gently massaging all over.",
    roughPlayLvl: -25,
    seductionResistance: 12,
    grapplingProwess: 14,
    tongueProwess: 20,
    touchProwess: 22,
    cockProwess: 15,
    vaginaProwess: 15,
    anusProwess: 10,
    touchResistance: 15,
    breastResistance: 10,
    mouthResistance: 10,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 10,
    orgasmLimit: 3,
  },
  jenniLee: {
    seriesId,
    id: "jenniLee",
    name: "Jenni Lee",
    description:
      "Jenni Lee is a porn star of Puerto Rican, German and Irish ancestry",
    img: JenniLee,
    icon: JenniLeeIcon,
    opWinImg: JenniLeeWin,
    opLoseImg: JenniLeeLose,
    opLoseLesImg: JenniLeeLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "lithe",
    eyeColor: "blue",
    hairColor: "brown",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText:
      "Greedily Jenni gobbles up xxxx's cock, sucking on it as hard as she can, hoping to draw every last drop of semen into her throat.",
    roughPlayLvl: 5,
    seductionResistance: 12,
    grapplingProwess: 8,
    tongueProwess: 25,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 10,
    anusProwess: 20,
    touchResistance: 15,
    breastResistance: 15,
    mouthResistance: 32,
    cockResistance: 15,
    vaginaResistance: 35,
    anusResistance: 10,
    orgasmLimit: 3,
  },
  lilyCarter: {
    seriesId,
    id: "lilyCarter",
    name: "Lily Carter",
    description:
      "Lily is a pornstar & Best Actress winner at the 2013 AVN.",
    img: LilyCarter,
    icon: LilyCarterIcon,
    opWinImg: LilyCarterWin,
    opLoseImg: LilyCarterLose,
    opLoseLesImg: LilyCarterLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "lithe",
    eyeColor: "blue",
    hairColor: "brunette",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Lily gropes xxxx's breasts roughly, teasing the soft breast-meat and whispering alluring words into her ear.",
    roughPlayLvl: -10,
    seductionResistance: 12,
    grapplingProwess: 16,
    tongueProwess: 25,
    touchProwess: 40,
    cockProwess: 15,
    vaginaProwess: 15,
    anusProwess: 15,
    touchResistance: 25,
    breastResistance: 65,
    mouthResistance: 50,
    cockResistance: 15,
    vaginaResistance: 15,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  riley: {
    seriesId,
    id: "riley",
    name: "Riley Reid",
    description:
      "Riley Reid is one of the hottest all natural pornstars in the adult industry.",
    img: Riley,
    icon: RileyIcon,
    opWinImg: RileyWin,
    opLoseImg: RileyLose,
    opLoseLesImg: RileyLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "green",
    hairColor: "brunette",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 1,
    prefAttack: "vagina",
    prefTarget: "cock",
    uniqueAttackText:
      "Like a hungry semen demon, Riley rides xxxx's cock shuddering as it enters her tight pussy.",
    roughPlayLvl: 20,
    seductionResistance: 12,
    grapplingProwess: 10,
    tongueProwess: 25,
    touchProwess: 10,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 15,
    vaginaResistance: 35,
    anusResistance: 20,
    orgasmLimit: 3,
  },
};
