import alice from "../../assets/combatants/underground/hard/alice.jpg";
import anna from "../../assets/combatants/underground/hard/anna.jpg";
import anne from "../../assets/combatants/underground/hard/anne.jpg";
import cora from "../../assets/combatants/underground/hard/cora.jpg";
import elena from "../../assets/combatants/underground/hard/elena.jpg";
import emery from "../../assets/combatants/underground/hard/emery.jpg";
import eva from "../../assets/combatants/underground/hard/eva.jpg";
import hailey from "../../assets/combatants/underground/hard/hailey.jpg";
import ivy from "../../assets/combatants/underground/hard/ivy.jpg";
import janine from "../../assets/combatants/underground/hard/janine.jpg";
import joanne from "../../assets/combatants/underground/hard/joanne.jpg";
import juno from "../../assets/combatants/underground/hard/juno.jpg";
import kate from "../../assets/combatants/underground/hard/kate.jpg";
import kennedy from "../../assets/combatants/underground/hard/kennedy.jpg";
import maria from "../../assets/combatants/underground/hard/maria.jpg";
import nancy from "../../assets/combatants/underground/hard/nancy.jpg";
import piper from "../../assets/combatants/underground/hard/piper.jpg";
import ruby from "../../assets/combatants/underground/hard/ruby.jpg";
import sarah from "../../assets/combatants/underground/hard/sarah.jpg";
import stephanie from "../../assets/combatants/underground/hard/stephanie.jpg";
import tessa from "../../assets/combatants/underground/hard/tessa.jpg";

export default {
  alice: {
    name: "Alice",
    description: "A powerful fitness enthusiast",
    img: alice,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "touch",
    uniqueAttackText: "Alice roughly licks xxxx's armpits and neck",
    roughPlayLvl: 12,
    seductionResistance: 45,
    grapplingProwess: 60,
    tongueProwess: 65,
    touchProwess: 45,
    cockProwess: 35,
    vaginaProwess: 38,
    anusProwess: 45,
    // -- resistance is ability to endure pleasure, 1 - 100
    touchResistance: 45,
    breastResistance: 40,
    mouthResistance: 50,
    cockResistance: 60,
    vaginaResistance: 35,
    anusResistance: 50,
    orgasmLimit: 3,
  },
  anna: {
    name: "Anna",
    description:
      "A seductive dom who likes to dish out spankings.",
    img: anna,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Anna piperughly gropes xxxx's breasts,in a dominant manner",
    roughPlayLvl: 25,
    seductionResistance: 45,
    grapplingProwess: 62,
    tongueProwess: 45,
    touchProwess: 65,
    cockProwess: 25,
    vaginaProwess: 30,
    anusProwess: 22,
    touchResistance: 35,
    breastResistance: 40,
    mouthResistance: 30,
    cockResistance: 55,
    vaginaResistance: 55,
    anusResistance: 50,
    orgasmLimit: 3,
  },
  anne: {
    name: "Anne",
    description: "A slutty milf, with a bad reputation.",
    img: anne,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "breasts",
    prefTarget: "breasts",
    uniqueAttackText:
      "Anne smothers xxxx's breasts with her hard nipples, teasing her as she does so.",
    roughPlayLvl: 25,
    seductionResistance: 55,
    grapplingProwess: 40,
    tongueProwess: 35,
    touchProwess: 25,
    cockProwess: 65,
    vaginaProwess: 45,
    anusProwess: 30,
    touchResistance: 25,
    breastResistance: 30,
    mouthResistance: 45,
    cockResistance: 50,
    vaginaResistance: 35,
    anusResistance: 44,
    orgasmLimit: 3,
  },
  cora: {
    name: "Cora",
    description:
      "A sweet ex-personal trainer fired for punishing customers with a rough dildo-ing.",
    img: cora,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "pussy",
    prefTarget: "mouth",
    uniqueAttackText:
      "Cora drives her sweaty, sweet lips over xxxx's whole face.",
    roughPlayLvl: 32,
    seductionResistance: 35,
    grapplingProwess: 55,
    tongueProwess: 35,
    touchProwess: 45,
    cockProwess: 65,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 40,
    breastResistance: 40,
    mouthResistance: 30,
    cockResistance: 55,
    vaginaResistance: 35,
    anusResistance: 30,
    orgasmLimit: 3,
  },
  elena: {
    name: "Elena",
    description: "A small but capable fighter",
    img: elena,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "cock",
    prefTarget: "vagina",
    uniqueAttackText:
      "Elena teasingly slips her strapon in and out of xxxx's hungry pussy.",
    roughPlayLvl: 22,
    seductionResistance: 45,
    grapplingProwess: 42,
    tongueProwess: 45,
    touchProwess: 55,
    cockProwess: 50,
    vaginaProwess: 38,
    anusProwess: 65,
    touchResistance: 35,
    breastResistance: 43,
    mouthResistance: 50,
    cockResistance: 60,
    vaginaResistance: 35,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  emery: {
    name: "Emery",
    description: "An experienced and commanding super slut.",
    img: emery,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText:
      "Emery rides xxxx's cock all the while chastising them for being a arse loving slut.",
    roughPlayLvl: 12,
    seductionResistance: 52,
    grapplingProwess: 45,
    tongueProwess: 35,
    touchProwess: 45,
    cockProwess: 35,
    vaginaProwess: 40,
    anusProwess: 35,
    touchResistance: 15,
    breastResistance: 50,
    mouthResistance: 60,
    cockResistance: 25,
    vaginaResistance: 65,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  eva: {
    name: "Eva",
    description:
      "A popular prostitute known for the thick loads she hungrily swallows.",
    img: eva,
    gender: "female",
    isWoman: true,
    hasCock: true,
    height: 3,
    penisSize: 3,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "breasts",
    prefTarget: "touch",
    uniqueAttackText:
      "Eva expertly teases xxxx with her breasts, running them over their face and slapping them with it.",
    roughPlayLvl: 10,
    seductionResistance: 45,
    grapplingProwess: 52,
    tongueProwess: 55,
    touchProwess: 25,
    cockProwess: 50,
    vaginaProwess: 35,
    anusProwess: 40,
    touchResistance: 45,
    breastResistance: 50,
    mouthResistance: 30,
    cockResistance: 50,
    vaginaResistance: 65,
    anusResistance: 40,
    orgasmLimit: 3,
  },
  hailey: {
    name: "Hailey",
    description: "A strongy slutty young student & gym bunny.",
    img: hailey,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 1,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText:
      "Hailey's little fingers explore the inside of xxxx's pussy with eagerness.",
    roughPlayLvl: -20,
    seductionResistance: 45,
    grapplingProwess: 65,
    tongueProwess: 65,
    touchProwess: 35,
    cockProwess: 65,
    vaginaProwess: 45,
    anusProwess: 60,
    touchResistance: 25,
    breastResistance: 50,
    mouthResistance: 30,
    cockResistance: 45,
    vaginaResistance: 25,
    anusResistance: 30,
    orgasmLimit: 3,
  },
  ivy: {
    name: "Ivy",
    description: "A milf with a love of being gangbanged.",
    img: ivy,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "anus",
    uniqueAttackText:
      "Ivy drives her fist nervously into xxxx's back passage, rubbing expertly against the inner g spot.",
    roughPlayLvl: 12,
    seductionResistance: 45,
    grapplingProwess: 55,
    tongueProwess: 55,
    touchProwess: 25,
    cockProwess: 50,
    vaginaProwess: 58,
    anusProwess: 25,
    touchResistance: 65,
    breastResistance: 35,
    mouthResistance: 60,
    cockResistance: 40,
    vaginaResistance: 35,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  janine: {
    name: "Janine",
    description: "A respected bodybuilder.",
    img: janine,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "touch",
    prefTarget: "touch",
    uniqueAttackText:
      "Embracing xxxx in a bear hug, Janine rubs her body against them erotically.",
    roughPlayLvl: 5,
    seductionResistance: 42,
    grapplingProwess: 70,
    tongueProwess: 45,
    touchProwess: 55,
    cockProwess: 35,
    vaginaProwess: 40,
    anusProwess: 55,
    touchResistance: 55,
    breastResistance: 40,
    mouthResistance: 40,
    cockResistance: 35,
    vaginaResistance: 65,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  joanne: {
    name: "Joanne",
    description: "Despite her experience she is a capable dom.",
    img: joanne,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 4,
    anusSize: 4,
    throatSize: 4,
    prefAttack: "tongue",
    prefTarget: "touch",
    uniqueAttackText:
      "Joanne's tongue fires electric bolts of sexual energy along xxxx's body as it slides over it.",
    roughPlayLvl: -15,
    seductionResistance: 64,
    grapplingProwess: 34,
    tongueProwess: 52,
    touchProwess: 65,
    cockProwess: 30,
    vaginaProwess: 45,
    anusProwess: 42,
    touchResistance: 22,
    breastResistance: 40,
    mouthResistance: 30,
    cockResistance: 55,
    vaginaResistance: 35,
    anusResistance: 34,
    orgasmLimit: 3,
  },
  juno: {
    name: "Juno",
    description: "A cute but dedicated weightlifter.",
    img: juno,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText:
      "Juno hungrily drives her tongue around the inside of xxxx's pussy.",
    roughPlayLvl: 12,
    seductionResistance: 35,
    grapplingProwess: 65,
    tongueProwess: 35,
    touchProwess: 45,
    cockProwess: 55,
    vaginaProwess: 35,
    anusProwess: 50,
    touchResistance: 25,
    breastResistance: 30,
    mouthResistance: 40,
    cockResistance: 25,
    vaginaResistance: 55,
    anusResistance: 42,
    orgasmLimit: 3,
  },
  kate: {
    name: "Kate",
    description: "A very popular dom-in-training.",
    img: kate,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 4,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText:
      "Kate expertly pumps xxxx's cock, whilst also pressing her small rack into their back.",
    roughPlayLvl: 10,
    seductionResistance: 42,
    grapplingProwess: 55,
    tongueProwess: 55,
    touchProwess: 45,
    cockProwess: 45,
    vaginaProwess: 30,
    anusProwess: 45,
    touchResistance: 55,
    breastResistance: 35,
    mouthResistance: 50,
    cockResistance: 35,
    vaginaResistance: 35,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  kennedy: {
    name: "Kennedy",
    description: "A commanding and beautiful dom.",
    img: kennedy,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Kennedy gently massages xxxx's breasts, licking her neck as she does.",
    roughPlayLvl: 35,
    seductionResistance: 64,
    grapplingProwess: 66,
    tongueProwess: 42,
    touchProwess: 55,
    cockProwess: 40,
    vaginaProwess: 45,
    anusProwess: 32,
    touchResistance: 42,
    breastResistance: 25,
    mouthResistance: 50,
    cockResistance: 55,
    vaginaResistance: 35,
    anusResistance: 34,
    orgasmLimit: 3,
  },
  maria: {
    name: "Maria",
    description:
      "Has a record for repeatedly pressing herself against young schoolgirls on the train.",
    img: maria,
    gender: "female",
    isWoman: true,
    hasCock: true,
    height: 3,
    penisSize: 4,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "cock",
    prefTarget: "anus",
    uniqueAttackText: "Maria roughly pounds xxxx's anus with her strapon.",
    roughPlayLvl: 10,
    seductionResistance: 45,
    grapplingProwess: 55,
    tongueProwess: 35,
    touchProwess: 55,
    cockProwess: 45,
    vaginaProwess: 45,
    anusProwess: 30,
    touchResistance: 35,
    breastResistance: 55,
    mouthResistance: 60,
    cockResistance: 35,
    vaginaResistance: 25,
    anusResistance: 62,
    orgasmLimit: 3,
  },
  nancy: {
    name: "Nancy",
    description: "An aerobics teacher with a reputation for seducing students.",
    img: nancy,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "touch",
    prefTarget: "mouth",
    uniqueAttackText:
      "Nancy jumps on xxxx and starts pressing her toned stomach to their face, intoxicating them with her delicious smell.",
    roughPlayLvl: 15,
    seductionResistance: 42,
    grapplingProwess: 30,
    tongueProwess: 45,
    touchProwess: 55,
    cockProwess: 65,
    vaginaProwess: 60,
    anusProwess: 65,
    touchResistance: 55,
    breastResistance: 40,
    mouthResistance: 40,
    cockResistance: 35,
    vaginaResistance: 65,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  piper: {
    name: "Piper",
    description: "A busty glamour model & mma amatuer.",
    img: piper,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 4,
    anusSize: 4,
    throatSize: 4,
    prefAttack: "tongue",
    prefTarget: "anus",
    uniqueAttackText:
      "Piper pushes her long tongue deep into xxxx's back passage as she smothers her face with xxxx's butt cheeks.",
    roughPlayLvl: -15,
    seductionResistance: 44,
    grapplingProwess: 54,
    tongueProwess: 52,
    touchProwess: 65,
    cockProwess: 40,
    vaginaProwess: 45,
    anusProwess: 42,
    touchResistance: 42,
    breastResistance: 40,
    mouthResistance: 40,
    cockResistance: 55,
    vaginaResistance: 35,
    anusResistance: 34,
    orgasmLimit: 3,
  },
  ruby: {
    name: "Ruby",
    description:
      "A seductive tyrant who made her female students her sex slaves.",
    img: ruby,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "vagina",
    prefTarget: "mouth",
    uniqueAttackText: "Ruby forces her tight pussy over xxxx's face.",
    roughPlayLvl: 32,
    seductionResistance: 45,
    grapplingProwess: 35,
    tongueProwess: 55,
    touchProwess: 45,
    cockProwess: 55,
    vaginaProwess: 65,
    anusProwess: 50,
    touchResistance: 25,
    breastResistance: 40,
    mouthResistance: 40,
    cockResistance: 25,
    vaginaResistance: 45,
    anusResistance: 42,
    orgasmLimit: 3,
  },
  sarah: {
    name: "Sarah",
    description: "Featherweigth boxing champ.",
    img: sarah,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 4,
    throatSize: 4,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText: "Sarah rides xxxx's cock with her hungry arse.",
    roughPlayLvl: 15,
    seductionResistance: 52,
    grapplingProwess: 55,
    tongueProwess: 35,
    touchProwess: 45,
    cockProwess: 55,
    vaginaProwess: 40,
    anusProwess: 45,
    touchResistance: 55,
    breastResistance: 45,
    mouthResistance: 50,
    cockResistance: 55,
    vaginaResistance: 35,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  stephanie: {
    name: "Stephanie",
    description: "A seductive gymnastics olympian of high repute.",
    img: stephanie,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "touch",
    uniqueAttackText:
      "Stephanie rubs her glistening and soft body against xxxx's.",
    roughPlayLvl: 5,
    seductionResistance: 64,
    grapplingProwess: 46,
    tongueProwess: 52,
    touchProwess: 55,
    cockProwess: 40,
    vaginaProwess: 45,
    anusProwess: 32,
    touchResistance: 42,
    breastResistance: 55,
    mouthResistance: 50,
    cockResistance: 45,
    vaginaResistance: 35,
    anusResistance: 34,
    orgasmLimit: 3,
  },
  tessa: {
    name: "Tessa",
    description: "A well know show girl and dancer.",
    img: tessa,
    gender: "female",
    isWoman: true,
    hasCock: false,
    height: 2,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Tessa rubs her soft rack against xxxx's tits, running round her nipples in little circles.",
    roughPlayLvl: -5,
    seductionResistance: 45,
    grapplingProwess: 45,
    tongueProwess: 45,
    touchProwess: 55,
    cockProwess: 45,
    vaginaProwess: 35,
    anusProwess: 30,
    touchResistance: 45,
    breastResistance: 55,
    mouthResistance: 60,
    cockResistance: 35,
    vaginaResistance: 25,
    anusResistance: 52,
    orgasmLimit: 3,
  },
};
