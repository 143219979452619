import AlexandraDaddario from "../../assets/combatants/actress-b/alexandra-daddario.jpg";
import AlexandraDaddarioIcon from "../../assets/combatants/actress-b/alexandra-daddario-icon.jpg";
import AlexandraDaddarioWin from "../../assets/combatants/actress-b/alexandra-daddario-win.jpg";
import AlexandraDaddarioLose from "../../assets/combatants/actress-b/alexandra-daddario-lose.jpg";
import AlexandraDaddarioLes from "../../assets/combatants/actress-b/alexandra-daddario-lesb.jpg";

import DaisyRidley from "../../assets/combatants/actress-b/daisy-ridley.jpg";
import DaisyRidleyIcon from "../../assets/combatants/actress-b/daisy-ridley-icon.jpg";
import DaisyRidleyWin from "../../assets/combatants/actress-b/daisy-ridley-win.jpg";
import DaisyRidleyLose from "../../assets/combatants/actress-b/daisy-ridley-lose.jpg";
import DaisyRidleyLes from "../../assets/combatants/actress-b/daisy-ridley-lesb.jpg";

import FelicityJones from "../../assets/combatants/actress-b/felicity-jones.jpg";
import FelicityJonesIcon from "../../assets/combatants/actress-b/felicity-jones-icon.jpg";
import FelicityJonesWin from "../../assets/combatants/actress-b/felicity-jones-win.jpg";
import FelicityJonesLose from "../../assets/combatants/actress-b/felicity-jones-lose.jpg";

import KateMara from "../../assets/combatants/actress-b/kate-mara.jpg";
import KateMaraIcon from "../../assets/combatants/actress-b/kate-mara-icon.jpg";
import KateMaraWin from "../../assets/combatants/actress-b/kate-mara-win.jpg";
import KateMaraLose from "../../assets/combatants/actress-b/kate-mara-lose.jpg";
import KateMaraLes from "../../assets/combatants/actress-b/kate-mara-lesb.jpg";

import KatherynWinnick from "../../assets/combatants/actress-b/katheryn-winnick.jpg";
import KatherynWinnickIcon from "../../assets/combatants/actress-b/katheryn-winnick-icon.jpg";
import KatherynWinnickWin from "../../assets/combatants/actress-b/katheryn-winnick-win.jpg";
import KatherynWinnickLose from "../../assets/combatants/actress-b/katheryn-winnick-lose.jpg";

import LilyCollins from "../../assets/combatants/actress-b/lily-collins.jpg";
import LilyCollinsIcon from "../../assets/combatants/actress-b/lily-collins-icon.jpg";
import LilyCollinsWin from "../../assets/combatants/actress-b/lily-collins-win.jpg";
import LilyCollinsLose from "../../assets/combatants/actress-b/lily-collins-lose.jpg";

import NataliePortman from "../../assets/combatants/actress-b/natalie-portman.jpg";
import NataliePortmanIcon from "../../assets/combatants/actress-b/natalie-portman-icon.jpg";
import NataliePortmanWin from "../../assets/combatants/actress-b/natalie-portman-win.jpg";
import NataliePortmanLose from "../../assets/combatants/actress-b/natalie-portman-lose.jpg";
import NataliePortmanLes from "../../assets/combatants/actress-b/natalie-portman-lesb.jpg";

import OliviaWilde from "../../assets/combatants/actress-b/olivia-wilde.jpg";
import OliviaWildeIcon from "../../assets/combatants/actress-b/olivia-wilde-icon.jpg";
import OliviaWildeWin from "../../assets/combatants/actress-b/olivia-wilde-win.jpg";
import OliviaWildeLose from "../../assets/combatants/actress-b/olivia-wilde-lose.jpg";
import OliviaWildeLes from "../../assets/combatants/actress-b/olivia-wilde-lesb.jpg";

import RooneyMara from "../../assets/combatants/actress-b/rooney-mara.jpg";
import RooneyMaraIcon from "../../assets/combatants/actress-b/rooney-mara-icon.jpg";
import RooneyMaraWin from "../../assets/combatants/actress-b/rooney-mara-win.jpg";
import RooneyMaraLose from "../../assets/combatants/actress-b/rooney-mara-lose.jpg";
import RooneyMaraLes from "../../assets/combatants/actress-b/rooney-mara.jpg";

export const seriesId = "Actresses - B";

export default {
  AlexandraDaddario: {
    seriesId,
    id: "Alexandra Daddario",
    name: "AlexandraDaddario",
    description: "",
    img: AlexandraDaddario,
    icon: AlexandraDaddarioIcon,
    opWinImg: AlexandraDaddarioWin,
    opLoseImg: AlexandraDaddarioLose,
    opLoseLesImg: AlexandraDaddarioLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "blue",
    hairColor: "brunette",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "mouth",
    uniqueAttackText:
      "Alexandra rubs her massive sweaty breasts over xxxx's face.",
    roughPlayLvl: 12,
    seductionResistance: 45,
    grapplingProwess: 60,
    tongueProwess: 45,
    touchProwess: 52,
    cockProwess: 25,
    vaginaProwess: 30,
    anusProwess: 25,
    touchResistance: 45,
    breastResistance: 60,
    mouthResistance: 30,
    cockResistance: 55,
    vaginaResistance: 45,
    anusResistance: 18,
    orgasmLimit: 3,
  },
  DaisyRidley: {
    seriesId,
    id: "DaisyRidley",
    name: "Daisy Ridley",
    description: "",
    img: DaisyRidley,
    icon: DaisyRidleyIcon,
    opWinImg: DaisyRidleyWin,
    opLoseImg: DaisyRidleyLose,
    opLoseLesImg: DaisyRidleyLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "black",
    hairColor: "brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText: "Daisey presses her tight anus over xxxx's hard cock.",
    roughPlayLvl: -25,
    seductionResistance: 45,
    grapplingProwess: 55,
    tongueProwess: 45,
    touchProwess: 35,
    cockProwess: 20,
    vaginaProwess: 30,
    anusProwess: 25,
    touchResistance: 15,
    breastResistance: 30,
    mouthResistance: 54,
    cockResistance: 30,
    vaginaResistance: 45,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  FelicityJones: {
    seriesId,
    id: "FelicityJones",
    name: "Felicity Jones",
    description: "",
    img: FelicityJones,
    icon: FelicityJonesIcon,
    opWinImg: FelicityJonesWin,
    opLoseImg: FelicityJonesLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "brown",
    hairColor: "brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "touch",
    uniqueAttackText:
      "Felicity rubs her soft, petite body against xxxx's pressing her tiny breasts into their back.",
    roughPlayLvl: -8,
    seductionResistance: 42,
    grapplingProwess: 45,
    tongueProwess: 35,
    touchProwess: 58,
    cockProwess: 25,
    vaginaProwess: 45,
    anusProwess: 25,
    touchResistance: 58,
    breastResistance: 25,
    mouthResistance: 35,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  KateMara: {
    seriesId,
    id: "KateMara",
    name: "Kate Mara",
    description: "",
    img: KateMara,
    icon: KateMaraIcon,
    opWinImg: KateMaraWin,
    opLoseImg: KateMaraLose,
    opLoseLesImg: KateMaraLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "soft",
    eyeColor: "brown",
    hairColor: "light brown",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "vagina",
    prefTarget: "mouth",
    uniqueAttackText:
      "Kate runs her soft pussy lips over xxxx's mouth, letting their pussy juice drip into their mouth.",
    roughPlayLvl: 25,
    seductionResistance: 52,
    grapplingProwess: 62,
    tongueProwess: 25,
    touchProwess: 45,
    cockProwess: 15,
    vaginaProwess: 55,
    anusProwess: 25,
    touchResistance: 35,
    breastResistance: 60,
    mouthResistance: 32,
    cockResistance: 15,
    vaginaResistance: 55,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  KatherynWinnick: {
    seriesId,
    id: "KatherynWinnick",
    name: "Katheryn Winnick",
    description: "",
    img: KatherynWinnick,
    icon: KatherynWinnickIcon,
    opWinImg: KatherynWinnickWin,
    opLoseImg: KatherynWinnickLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "touch",
    uniqueAttackText:
      "Katheryn slides her wet pussy over xxxx's thighs, whilst staring seductively into their eyes.",
    roughPlayLvl: -12,
    seductionResistance: 45,
    grapplingProwess: 50,
    tongueProwess: 55,
    touchProwess: 35,
    cockProwess: 15,
    vaginaProwess: 45,
    anusProwess: 10,
    touchResistance: 45,
    breastResistance: 25,
    mouthResistance: 20,
    cockResistance: 25,
    vaginaResistance: 35,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  LilyCollins: {
    seriesId,
    id: "LilyCollins",
    name: "Lily Collins",
    description: "",
    img: LilyCollins,
    icon: LilyCollinsIcon,
    opWinImg: LilyCollinsWin,
    opLoseImg: LilyCollinsLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "brown",
    hairColor: "brown",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "anus",
    prefTarget: "mouth",
    uniqueAttackText:
      "Lily pushes her soft, plushy arse-cheeks over xxxx's face.",
    roughPlayLvl: 12,
    seductionResistance: 45,
    grapplingProwess: 40,
    tongueProwess: 30,
    touchProwess: 20,
    cockProwess: 25,
    vaginaProwess: 15,
    anusProwess: 35,
    touchResistance: 35,
    breastResistance: 25,
    mouthResistance: 35,
    cockResistance: 25,
    vaginaResistance: 30,
    anusResistance: 55,
    orgasmLimit: 3,
  },
  NataliePortman: {
    seriesId,
    id: "NataliePortman",
    name: "Natalie Portman",
    description: "",
    img: NataliePortman,
    icon: NataliePortmanIcon,
    opWinImg: NataliePortmanWin,
    opLoseImg: NataliePortmanLose,
    opLoseLesImg: NataliePortmanLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "brown",
    hairColor: "Auburn",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "vagina",
    uniqueAttackText:
      "Natalie scissors xxxx's pussy with an eager sexual energy.",
    roughPlayLvl: -12,
    seductionResistance: 52,
    grapplingProwess: 45,
    tongueProwess: 32,
    touchProwess: 20,
    cockProwess: 35,
    vaginaProwess: 25,
    anusProwess: 35,
    touchResistance: 15,
    breastResistance: 35,
    mouthResistance: 40,
    cockResistance: 25,
    vaginaResistance: 55,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  OliviaWilde: {
    seriesId,
    id: "OliviaWilde",
    name: "Olivia Wilde",
    description: "",
    img: OliviaWilde,
    icon: OliviaWildeIcon,
    opWinImg: OliviaWildeWin,
    opLoseImg: OliviaWildeLose,
    opLoseLesImg: OliviaWildeLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "blue",
    hairColor: "brown",
    skinColor: "pale",
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText: "Olivia slides behind xxxx and softly fingers her pussy.",
    roughPlayLvl: 25,
    seductionResistance: 52,
    grapplingProwess: 52,
    tongueProwess: 25,
    touchProwess: 52,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 45,
    touchResistance: 55,
    breastResistance: 45,
    mouthResistance: 60,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  RooneyMara: {
    seriesId,
    id: "RooneyMara",
    name: "Rooney Mara",
    description: "",
    img: RooneyMara,
    icon: RooneyMaraIcon,
    opWinImg: RooneyMaraWin,
    opLoseImg: RooneyMaraLose,
    opLoseLesImg: RooneyMaraLes,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "grey",
    hairColor: "brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText:
      "Rooney rubs her lithe, petite body against xxxx's pulsing cock.",
    roughPlayLvl: 5,
    seductionResistance: 42,
    grapplingProwess: 48,
    tongueProwess: 25,
    touchProwess: 50,
    cockProwess: 35,
    vaginaProwess: 35,
    anusProwess: 25,
    touchResistance: 45,
    breastResistance: 35,
    mouthResistance: 20,
    cockResistance: 25,
    vaginaResistance: 35,
    anusResistance: 25,
    orgasmLimit: 3,
  },
};
