import AlexisBledel from "../../assets/combatants/actress-a/alexis-bledel.jpg";
import AlexisBledelIcon from "../../assets/combatants/actress-a/alexis-bledel-icon.jpg";
import AlexisBledelWin from "../../assets/combatants/actress-a/alexis-bledel-win.jpeg";
import AlexisBledelLose from "../../assets/combatants/actress-a/alexis-bledel-lose.jpg";

import AlisonBrie from "../../assets/combatants/actress-a/alison-brie.jpg";
import AlisonBrieIcon from "../../assets/combatants/actress-a/alison-brie-icon.jpg";
import AlisonBrieWin from "../../assets/combatants/actress-a/alison-brie-win.jpg";
import AlisonBrieLose from "../../assets/combatants/actress-a/alison-brie-lose.jpg";

import AnneHathaway from "../../assets/combatants/actress-a/anne-hathaway.jpg";
import AnneHathawayIcon from "../../assets/combatants/actress-a/anne-hathaway-icon.jpg";
import AnneHathawayWin from "../../assets/combatants/actress-a/anne-hathaway-win.jpg";
import AnneHathawayLose from "../../assets/combatants/actress-a/anne-hathaway-lose.jpg";

import EmmaRoberts from "../../assets/combatants/actress-a/emma-roberts.jpg";
import EmmaRobertsIcon from "../../assets/combatants/actress-a/emma-roberts-icon.jpg";
import EmmaRobertsWin from "../../assets/combatants/actress-a/emma-roberts-win.jpg";
import EmmaRobertsLose from "../../assets/combatants/actress-a/emma-roberts-lose.jpg";

import EmmaStone from "../../assets/combatants/actress-a/emma-stone.jpg";
import EmmaStoneIcon from "../../assets/combatants/actress-a/emma-stone-icon.jpg";
import EmmaStoneWin from "../../assets/combatants/actress-a/emma-stone-win.jpg";
import EmmaStoneLose from "../../assets/combatants/actress-a/emma-stone-lose.jpg";

import EmmaWatson from "../../assets/combatants/actress-a/emma-watson.jpg";
import EmmaWatsonIcon from "../../assets/combatants/actress-a/emma-watson-icon.jpg";
import EmmaWatsonWin from "../../assets/combatants/actress-a/emma-watson-win.jpg";
import EmmaWatsonLose from "../../assets/combatants/actress-a/emma-watson-lose.jpg";

import KieraKnightly from "../../assets/combatants/actress-a/kiera-knightly.jpg";
import KieraKnightlyIcon from "../../assets/combatants/actress-a/kiera-knightly-icon.jpg";
import KieraKnightlyWin from "../../assets/combatants/actress-a/kiera-knightly-win.jpg";
import KieraKnightlyLose from "../../assets/combatants/actress-a/kiera-knightly-lose.jpg";

import LaurenCohen from "../../assets/combatants/actress-a/lauren-cohen.jpg";
import LaurenCohenIcon from "../../assets/combatants/actress-a/lauren-cohen-icon.jpg";
import LaurenCohenWin from "../../assets/combatants/actress-a/lauren-cohen-win.jpg";
import LaurenCohenLose from "../../assets/combatants/actress-a/lauren-cohen-lose.jpg";

import MeganFox from "../../assets/combatants/actress-a/megan-fox.jpg";
import MeganFoxIcon from "../../assets/combatants/actress-a/megan-fox-icon.jpg";
import MeganFoxWin from "../../assets/combatants/actress-a/megan-fox-win.jpg";
import MeganFoxLose from "../../assets/combatants/actress-a/megan-fox-lose.jpg";

export const seriesId = "Actresses - A";

export default {
  AlexisBledel: {
    seriesId,
    id: "AlexisBledel",
    name: "Alexis Bledel",
    description: "The cutie from Gilmore girls",
    img: AlexisBledel,
    icon: AlexisBledelIcon,
    opWinImg: AlexisBledelWin,
    opLoseImg: AlexisBledelLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "youthful",
    eyeColor: "blue",
    hairColor: "brunette",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText: "Alexis rides xxxx's cock with an animal hunger.",
    roughPlayLvl: -10,
    seductionResistance: 45,
    grapplingProwess: 25,
    tongueProwess: 25,
    touchProwess: 30,
    cockProwess: 15,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  AlisonBrie: {
    seriesId,
    id: "AlisonBrie",
    name: "Alison Brie",
    description: "The cutest girl in community.",
    img: AlisonBrie,
    icon: AlisonBrieIcon,
    opWinImg: AlisonBrieWin,
    opLoseImg: AlisonBrieLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "blue",
    hairColor: "brunette",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText:
      "Alison uses her tongue powerfully on xxxx's quivering clit.",
    roughPlayLvl: -10,
    seductionResistance: 25,
    grapplingProwess: 25,
    tongueProwess: 45,
    touchProwess: 20,
    cockProwess: 35,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 35,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  AnneHathaway: {
    seriesId,
    id: "AnneHathaway",
    name: "Anne Hathaway",
    description: "A beautiful english rose, and capable actress",
    img: AnneHathaway,
    icon: AnneHathawayIcon,
    opWinImg: AnneHathawayWin,
    opLoseImg: AnneHathawayLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "brown",
    hairColor: "auburn",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 1,
    prefAttack: "touch",
    prefTarget: "mouth",
    uniqueAttackText: "Anne presses her breasts over xxxx's face.",
    roughPlayLvl: 12,
    seductionResistance: 25,
    grapplingProwess: 30,
    tongueProwess: 25,
    touchProwess: 40,
    cockProwess: 35,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 15,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 35,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  EmmaRoberts: {
    seriesId,
    id: "EmmaRoberts",
    name: "Emma Roberts",
    description: "Actress & singer, debuted in the crime film Blow",
    img: EmmaRoberts,
    icon: EmmaRobertsIcon,
    opWinImg: EmmaRobertsWin,
    opLoseImg: EmmaRobertsLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "brown",
    hairColor: "blonde",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "vagina",
    prefTarget: "touch",
    uniqueAttackText: "Emma grinds get lithe body over xxxx's chest.",
    roughPlayLvl: 10,
    seductionResistance: 42,
    grapplingProwess: 35,
    tongueProwess: 35,
    touchProwess: 40,
    cockProwess: 15,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 35,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  EmmaStone: {
    seriesId,
    id: "EmmaStone",
    name: "Emma Stone",
    description: "The world's highest-paid actress in 2017.",
    img: EmmaStone,
    icon: EmmaStoneIcon,
    opWinImg: EmmaStoneWin,
    opLoseImg: EmmaStoneLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "green",
    hairColor: "blonde",
    skinColor: "pale",
    height: 1,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText:
      "Emma's lither tongue hungry roots around xxxx's pussy.",
    roughPlayLvl: 12,
    seductionResistance: 35,
    grapplingProwess: 35,
    tongueProwess: 35,
    touchProwess: 30,
    cockProwess: 25,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 45,
    breastResistance: 35,
    mouthResistance: 40,
    cockResistance: 25,
    vaginaResistance: 35,
    anusResistance: 32,
    orgasmLimit: 3,
  },
  EmmaWatson: {
    seriesId,
    id: "EmmaWatson",
    name: "Emma Watson",
    description: "Hermoine, as did other things",
    img: EmmaWatson,
    icon: EmmaWatsonIcon,
    opWinImg: EmmaWatsonWin,
    opLoseImg: EmmaWatsonLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "black",
    hairColor: "brown",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText: "Emma gropes xxxx's tits expertly.",
    roughPlayLvl: 10,
    seductionResistance: 35,
    grapplingProwess: 45,
    tongueProwess: 25,
    touchProwess: 40,
    cockProwess: 15,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 15,
    vaginaResistance: 31,
    anusResistance: 22,
    orgasmLimit: 3,
  },
  KieraKnightly: {
    seriesId,
    id: "KieraKnightly",
    name: "Kiera Knightly",
    description: "Period drama specialist",
    img: KieraKnightly,
    icon: KieraKnightlyIcon,
    opWinImg: KieraKnightlyWin,
    opLoseImg: KieraKnightlyLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "thin",
    eyeColor: "black",
    hairColor: "brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText: "Kiera sucks xxxx's cock with an animalistic desire.",
    roughPlayLvl: 10,
    seductionResistance: 28,
    grapplingProwess: 35,
    tongueProwess: 25,
    touchProwess: 30,
    cockProwess: 15,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 35,
    breastResistance: 45,
    mouthResistance: 40,
    cockResistance: 25,
    vaginaResistance: 45,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  LaurenCohen: {
    seriesId,
    id: "LaurenCohen",
    name: "Lauren Cohen",
    description: " British-American actress best known for The Walking Dead",
    img: LaurenCohen,
    icon: LaurenCohenIcon,
    opWinImg: LaurenCohenWin,
    opLoseImg: LaurenCohenLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "slender",
    eyeColor: "light brown",
    hairColor: "brown",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText:
      "Lauren gently pleasures xxxx's quivering clit, with her long fingers.",
    roughPlayLvl: -10,
    seductionResistance: 25,
    grapplingProwess: 35,
    tongueProwess: 35,
    touchProwess: 30,
    cockProwess: 35,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 35,
    mouthResistance: 20,
    cockResistance: 25,
    vaginaResistance: 15,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  MeganFox: {
    seriesId,
    id: "MeganFox",
    name: "Megan Fox",
    description: " American actress and model, debuted in Holiday in the Sun",
    img: MeganFox,
    icon: MeganFoxIcon,
    opWinImg: MeganFoxWin,
    opLoseImg: MeganFoxLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "grey",
    hairColor: "black",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "anus",
    prefTarget: "mouth",
    uniqueAttackText: "Megan presses her soft bum over xxxx's face.",
    roughPlayLvl: 12,
    seductionResistance: 32,
    grapplingProwess: 28,
    tongueProwess: 25,
    touchProwess: 20,
    cockProwess: 31,
    vaginaProwess: 20,
    anusProwess: 35,
    touchResistance: 25,
    breastResistance: 35,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 15,
    anusResistance: 20,
    orgasmLimit: 3,
  },
};
